import {
	Box,
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	FormControlLabel,
	IconButton,
	InputAdornment,
	TextField,
	Tooltip,
} from '@mui/material';
import {
	ScheduleSlot,
	StaffWithProfession,
} from '../../rest-models/rest-model';
import { format } from 'date-fns';
import { ISODATEFMT, ISODATETIMEFMT } from '../../common/DateUtility';
import { TimePicker } from '@mui/x-date-pickers';
import { useEffect, useMemo, useState } from 'react';
import { Clear, Replay } from '@mui/icons-material';

interface StaffScheduleDialogProps {
	open: boolean;
	closeDialog: () => void;
	staff: StaffWithProfession;
	schedule: ScheduleSlot | undefined;
	currentDate: Date;

	saveAndExit: (
		staff: StaffWithProfession,
		scheduleSlot: ScheduleSlot,
		updateType: 'new' | 'delete' | 'update'
	) => void;
}

const resetWorkDates = (
	schedule: ScheduleSlot | undefined,
	currentDate: Date
) => {
	return {
		start: schedule
			? new Date(schedule.startTime)
			: new Date(`${format(currentDate, ISODATEFMT)}T07:30`),
		end: schedule
			? new Date(schedule.endTime)
			: new Date(`${format(currentDate, ISODATEFMT)}T16:00`),
	};
};

export function StaffScheduleDialog(props: StaffScheduleDialogProps) {
	const [workDates, setWorkDates] = useState<{ start: Date; end: Date }>(
		resetWorkDates(props.schedule, props.currentDate)
	);

	const [isAvailable, setIsAvailable] = useState<boolean>(
		props.schedule ? props.schedule.isAvailable : true
	);
	const [comment, setComment] = useState('');
	const fullName = `${props.staff.firstName} ${props.staff.lastName}`;

	const resetStates = () => {
		setWorkDates(resetWorkDates(props.schedule, props.currentDate));
		setComment(
			props.schedule && props.schedule.description
				? props.schedule.description
				: ''
		);
		setIsAvailable(props.schedule ? props.schedule.isAvailable : true);
	};

	useEffect(() => {
		resetStates();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.currentDate, props.staff, props.schedule]);

	const hasChanged = useMemo(() => {
		if (props.schedule) {
			return (
				isAvailable !== props.schedule.isAvailable ||
				comment !== props.schedule.description ||
				format(workDates.start, ISODATETIMEFMT) !== props.schedule.startTime ||
				format(workDates.end, ISODATETIMEFMT) !== props.schedule.endTime
			);
		} else return false;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [workDates, isAvailable, comment]);

	return (
		<Dialog open={props.open} maxWidth='xl'>
			<DialogTitle>
				{props.schedule ? 'Redigera arbetstid' : 'Lägg till ny arbetstid'}
			</DialogTitle>
			<DialogContent>
				<DialogContentText>{`${fullName}, ${format(
					props.currentDate,
					`${ISODATEFMT} eeee`
				)}`}</DialogContentText>

				<Box
					component='form'
					sx={{
						mt: 2,
						display: 'grid',
						gridTemplateColumns: '1fr 1fr',
						columnGap: 2,
						rowGap: 0,
					}}
				>
					<TimePicker
						label='start'
						ampm={false}
						value={workDates.start}
						onChange={newDate => {
							if (newDate) setWorkDates({ ...workDates, start: newDate });
						}}
					/>
					<TimePicker
						label='slut'
						ampm={false}
						value={workDates.end}
						onChange={newDate => {
							if (newDate) setWorkDates({ ...workDates, end: newDate });
						}}
					/>

					<TextField
						fullWidth
						label='Beskrivning'
						sx={{ gridColumn: '1/-1', mt: 2, mb: 1 }}
						rows={2}
						multiline
						value={comment}
						onChange={event => setComment(event.target.value)}
						InputProps={{
							endAdornment: (
								<InputAdornment position='start'>
									<Tooltip title='Ta bort all text'>
										<IconButton onClick={() => setComment('')}>
											<Clear />
										</IconButton>
									</Tooltip>
								</InputAdornment>
							),
						}}
					/>

					<Box
						sx={{
							gridColumn: '1/-1',
							display: 'flex',
							justifyContent: 'space-between',
						}}
					>
						<FormControlLabel
							sx={{
								m: 0,
								'.MuiButtonBase-root': { paddingLeft: 0 },
							}}
							control={
								<Checkbox
									checked={!isAvailable}
									onChange={event => setIsAvailable(!event.target.checked)}
								/>
							}
							label='Frånvaro'
						/>

						<Button
							disabled={!hasChanged}
							endIcon={<Replay />}
							onClick={() => resetStates()}
						>
							Återställ
						</Button>
					</Box>
				</Box>
			</DialogContent>

			<DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
				<Button
					color='error'
					disabled={props.schedule === undefined}
					onClick={() => {
						props.saveAndExit(
							props.staff,
							{
								startTime: format(workDates.start, ISODATETIMEFMT),
								endTime: format(workDates.end, ISODATETIMEFMT),
								description: comment,
								isAvailable: isAvailable,
							},
							'delete'
						);
						props.closeDialog();
					}}
				>
					Ta bort
				</Button>
				<Box sx={{ gap: 1, display: 'flex' }}>
					<Button onClick={props.closeDialog}>Avbryt</Button>
					<Button
						onClick={() => {
							props.saveAndExit(
								props.staff,
								{
									startTime: format(workDates.start, ISODATETIMEFMT),
									endTime: format(workDates.end, ISODATETIMEFMT),
									description: comment,
									isAvailable: isAvailable,
								},
								props.schedule === undefined ? 'new' : 'update'
							);
							props.closeDialog();
						}}
					>
						{props.schedule ? 'Uppdatera' : 'Lägg till'}
					</Button>
				</Box>
			</DialogActions>
		</Dialog>
	);
}
