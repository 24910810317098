import { Equipment } from '../../../rest-models/rest-model';
import { GenericFormProp, NameTextField } from '../Common';
import { Box, TextField } from '@mui/material';

export function EquipmentForm(props: GenericFormProp<Equipment>) {
	const { selected, formState, setFormState, setFormChanged } = props;

	return (
		<>
			<Box className='management-form-inner' component='form'>
				<TextField
					sx={{ gridRow: 1, gridColumn: 1 }}
					variant='standard'
					label='Id'
					disabled
					value={props.selected.id}
				/>

				<NameTextField
					sx={{
						gridRow: 2,
						gridColumn: 1,
					}}
					name={formState.name}
					setName={name => {
						setFormState({ ...props.selected, name });
						setFormChanged(true);
					}}
					disabled={selected.name === formState.name}
					reset={() => setFormState({ ...formState, name: selected.name })}
				/>

				<TextField
					sx={{
						gridColumn: 2,
						gridRow: '1/4',
					}}
					inputProps={{ style: { height: '100%' } }}
					label='Beskrivning'
					fullWidth
					multiline
					rows={4}
					value={formState.description}
					onChange={event => {
						setFormState({
							...formState,
							description: event.target.value,
						});
						setFormChanged(true);
					}}
				/>
			</Box>
		</>
	);
}
