import { AccountCircle, Home } from '@mui/icons-material';
import { KeycloakProfile } from 'keycloak-js';
import {
	AppBar,
	Button,
	IconButton,
	Stack,
	Toolbar,
	Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { LINKS_HIERARCHY } from '../../init-data';
import UserDialog from './UserDialog';
import { useLocation } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';

export function TopBar() {
	const { keycloak } = useKeycloak();

	const [username, setUsername] = useState<string>('You Ser');
	const [dialogOpen, setDialogOpen] = useState(false);

	const { pathname } = useLocation();

	useEffect(() => {
		keycloak.loadUserProfile().then((user: KeycloakProfile) => {
			setUsername(user.username ?? '');
		});
	}, [keycloak]);

	return (
		<>
			<UserDialog
				keycloak={keycloak}
				open={dialogOpen}
				onClose={() => {
					setDialogOpen(false);
				}}
				username={username}
			/>
			<AppBar component='nav' color='primary' position='sticky'>
				<Toolbar
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
						minWidth: t => t.breakpoints.values.sm,
						mx: 8,
					}}
				>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'flex-beginning',
						}}
					>
						<IconButton
							sx={{
								color: t => t.palette.primary.contrastText,
							}}
							key='home'
							href='/'
						>
							<Home />
						</IconButton>
						<Stack direction='row' spacing={3}>
							{LINKS_HIERARCHY.map((item, idx) => (
								<Button
									size='medium'
									key={item.name}
									href={`${item.mainUrl}`}
									sx={{
										padding: 0,
										color: t => t.palette.primary.contrastText,
										borderBottom: '2px solid',
										borderRadius: 0,
										borderColor: t =>
											pathname === `/${item.mainUrl}`
												? t.palette.primary.contrastText
												: 'transparent',
									}}
								>
									{item.name}
								</Button>
							))}
						</Stack>
					</Box>

					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							gap: t => t.spacing(4),
						}}
					>
						<IconButton
							disableRipple
							onClick={() => {
								setDialogOpen(true);
							}}
							sx={{
								color: t => t.palette.primary.contrastText,
								gap: 2,
							}}
						>
							<Typography variant='button'>{username}</Typography>
							<AccountCircle />
						</IconButton>
					</Box>
				</Toolbar>
			</AppBar>
		</>
	);
}
