/**
 * Init data for web app
 */

export interface SubLink {
	name: string;
	url: string;
}
export interface LinkHierarchy {
	name: string;
	mainUrl: string;
	subLinks: SubLink[];
}

export const LINKS_HIERARCHY: LinkHierarchy[] = [
	{
		name: 'Funktionsschema',
		mainUrl: 'function-schedule',
		subLinks: [],
	},
	{
		name: 'Personalschema',
		mainUrl: 'staff-schedule',
		subLinks: [],
	},
	{
		name: 'Schema',
		mainUrl: 'schedule',
		subLinks: [],
	},
	// {
	// 	name: 'Kompetenser',
	// 	mainUrl: 'staff-certificate-matrix',
	// 	subLinks: [
	// 		// { name: 'Exempel', url: 'example' }
	// 	],
	// },
	{
		name: 'Hantering',
		mainUrl: 'management',
		subLinks: [],
	},
];

export const linkToHref = (name: string) => {
	return LINKS_HIERARCHY.find(item => item.name === name);
};
