import { Box, Skeleton, Typography, Button } from '@mui/material';
import { useState, useEffect, useContext } from 'react';
import { FormButtonGroup, GenericFormProp, TList } from './Common';
import { Add } from '@mui/icons-material';
import { SnackbarContext } from '../../../context/SnackbarProvider';
import { AxiosError } from 'axios';

export interface DataModel<T> {
	getFunction: () => Promise<T[]>;
	postFunction: (newItem: T) => Promise<T>;
	deleteFunction: (number: number) => Promise<T>;
	putFunction: (item: T) => Promise<T>;
}

export interface GenericComponentProps<T> {
	dataModel: DataModel<T>;
	title: string;
	formComponent: (props: GenericFormProp<T>) => JSX.Element;
	defaultObject: T;
	newText: string;
	hideButtons?: boolean;
	postSaveFetch?: () => void;
}

export type FormMode = 'new' | 'edit';

export function GenericComponent<
	T extends { id: number; name: string; description: string | undefined }
>(props: GenericComponentProps<T>) {
	const { title, defaultObject, newText, hideButtons } = props;
	const {
		getFunction: fetchFunction,
		postFunction,
		deleteFunction,
		putFunction,
	} = props.dataModel;

	const [data, setData] = useState<{ data: T[]; loading: boolean }>({
		data: [],
		loading: true,
	});

	const [selectedItem, setSelectedItem] = useState<T | undefined>(undefined);

	const [formState, setFormState] = useState<T | undefined>(selectedItem);
	const [formChanged, setFormChanged] = useState(false);
	const [formMode, setFormMode] = useState<FormMode>('edit');
	const [shouldResetForm, setShouldResetForm] = useState(false);
	const [refetch, setRefetch] = useState(false);
	const [loadingSave, setLoadingSave] = useState(false);

	const { setShow } = useContext(SnackbarContext);

	useEffect(() => {
		if (!refetch) {
			return;
		}
		fetchFunction().then(res => setData({ data: res, loading: false }));
		setRefetch(false);
	}, [refetch, fetchFunction]);

	useEffect(() => {
		fetchFunction().then(res => setData({ data: res, loading: false }));
	}, [fetchFunction]);

	return (
		<Box className='grid-split'>
			{data.loading === true ? (
				<Skeleton />
			) : (
				<>
					<Box>
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'flex-end',
								border: 1,
								borderColor: 'divider',
								px: 1,
								py: 0.5,
							}}
						>
							<Typography variant='subtitle1'>{`${title} ${data.data.length} st`}</Typography>

							<Button
								variant='outlined'
								startIcon={<Add />}
								onClick={() => {
									setFormMode('new');
									setSelectedItem(defaultObject);
									setFormState(defaultObject);
								}}
							>
								Nytt
							</Button>
						</Box>
						<TList<T>
							items={data.data}
							selected={selectedItem}
							setSelected={item => {
								setFormMode('edit');
								setSelectedItem(item);
								setFormState(item);
								setFormChanged(false);
							}}
						/>
					</Box>

					<Box className='management-form-parent'>
						{selectedItem && (
							<>
								<Typography variant='h6' sx={{ mb: 4 }}>
									{formMode === 'new' ? newText : selectedItem.name}
								</Typography>

								<props.formComponent
									selected={selectedItem}
									setSelected={setSelectedItem}
									formState={formState ?? defaultObject}
									setFormState={setFormState}
									formMode={formMode}
									setFormMode={setFormMode}
									formChanged={formChanged}
									setFormChanged={setFormChanged}
									shouldResetForm={shouldResetForm}
									setShouldResetForm={setShouldResetForm}
									setRefetch={setRefetch}
								/>

								{!hideButtons && (
									<FormButtonGroup
										variant={formMode}
										hasChanged={formChanged}
										loading={loadingSave}
										reset={() => {
											setFormState(selectedItem);
											setFormChanged(false);
											setShouldResetForm(true);
										}}
										save={() => {
											if (!formState) {
												return;
											}
											setLoadingSave(true);
											putFunction(formState)
												.then(res => {
													setSelectedItem({ ...formState, id: res.id });
													setFormChanged(false);

													fetchFunction().then(res => {
														setData({ data: res, loading: false });
														setLoadingSave(false);
													});
													props.postSaveFetch?.();
													setShow({
														message: `${res.name} sparad`,
														messageType: 'Success',
													});
												})
												.catch((err: AxiosError) => {
													setShow({
														messageType: 'Error',
														message: `${err.message}`,
													});
													setLoadingSave(false);
												});
										}}
										add={() => {
											if (!formState) {
												return;
											}
											setLoadingSave(true);
											postFunction(formState)
												.then(res => {
													setSelectedItem({ ...formState, id: res.id });
													setFormState({ ...formState, id: res.id });
													setFormMode('edit');
													setFormChanged(false);
													fetchFunction().then(res => {
														setData({ data: res, loading: false });
														setLoadingSave(false);
													});
													props.postSaveFetch?.();
													setShow({
														message: `${res.name} sparad`,
														messageType: 'Success',
													});
												})
												.catch((err: AxiosError) =>
													setShow({
														message: err.message,
														messageType: 'Error',
													})
												);
										}}
										delete={() => {
											deleteFunction(selectedItem.id)
												.then(res => {
													setSelectedItem(undefined);
													setFormState(undefined);
													setFormChanged(false);
													fetchFunction().then(res =>
														setData({ data: res, loading: false })
													);
													props.postSaveFetch?.();
													setShow({
														message: `${res.name} sparades`,
														messageType: 'Success',
													});
												})
												.catch((err: AxiosError) =>
													setShow({
														message: err.message,
														messageType: 'Error',
													})
												);
										}}
									/>
								)}
							</>
						)}
					</Box>
				</>
			)}
		</Box>
	);
}
