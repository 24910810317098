import { Box } from '@mui/material';
import { TopBar } from '../common/Topbar';
import { Outlet } from 'react-router-dom';

export default function BaseLayout() {
	return (
		<>
			<TopBar />
			<Box padding={4}>
				<Outlet />
			</Box>
		</>
	);
}
