import { useEffect, useMemo, useState } from 'react';
import { Method, StaffWithProfession } from '../../../rest-models/rest-model';
import { GenericFormProp, NameTextField } from '../Common';
import { restServer } from '../../../../services/AxiosConfiguration';
import { Autocomplete, Box, TextField, Typography } from '@mui/material';

const getStaff = async () => {
	const result = await restServer.get<StaffWithProfession[]>('views/staff');
	return result.data;
};

export function MethodForm(props: GenericFormProp<Method>) {
	const { selected, formState, setFormState, setFormChanged } = props;

	const [staff, setStaff] = useState<StaffWithProfession[]>([]);
	useEffect(() => {
		getStaff().then(res => setStaff(res));
	}, []);

	const selectedStaff = useMemo<StaffWithProfession | null>(() => {
		return staff.find(st => st.id === formState.manager?.id) ?? null;
	}, [formState.manager, staff]);

	return (
		<>
			<Box component='form' className='management-form-inner'>
				<TextField
					sx={{ gridRowStart: 1, gridColumnStart: 1 }}
					variant='standard'
					label='Id'
					disabled
					value={props.selected.id}
				/>
				<TextField
					sx={{ gridRowStart: 2, gridColumnStart: 1 }}
					variant='standard'
					label='Undersökningskod i RIS/PACS'
					type='number'
					value={formState.code}
					onChange={event => {
						setFormState({
							...formState,
							code: Number(event.target.value),
						});
						setFormChanged(true);
					}}
				/>

				<NameTextField
					sx={{
						gridRowStart: 3,
						gridColumnStart: 1,
					}}
					name={formState.name}
					setName={name => {
						setFormState({ ...formState, name: name });
						setFormChanged(true);
					}}
					disabled={selected.name === formState.name}
					reset={() => {
						setFormState({ ...formState, name: selected.name });
					}}
				/>

				<Autocomplete
					sx={{ gridRowStart: 4, gridColumnStart: 1 }}
					options={staff}
					value={selectedStaff}
					getOptionLabel={item => item.abbrev}
					onChange={(_event, value) => {
						const manager =
							value !== null
								? {
										id: value.id,
										firstName: value.firstName,
										lastName: value.lastName,
										abbrev: value.abbrev,
										active: value.active,
										professionID: value.profession?.id,
								  }
								: undefined;
						setFormState({
							...formState,
							manager: manager,
						});
						setFormChanged(true);
					}}
					renderInput={params => {
						return (
							<TextField
								{...params}
								label='Välj metodansvarig'
								variant='standard'
							/>
						);
					}}
					size='medium'
					renderOption={(props, option, { selected }) => {
						return (
							<Box
								{...props}
								component='li'
								sx={{
									'&.MuiAutocomplete-option': {
										display: 'flex',
										justifyContent: 'space-between',
									},
								}}
							>
								<Box display={'flex'}>
									<Typography
										variant='body2'
										fontWeight='bold'
										sx={{ display: 'inline', mr: 1 }}
									>
										{option.abbrev}{' '}
									</Typography>
									<Typography
										variant='body2'
										color='#888'
										sx={{ display: 'inline' }}
									>
										{option.firstName} {option.lastName}
									</Typography>
								</Box>

								<Typography variant='body2' sx={{ display: 'inline' }}>
									{option.profession?.name}
								</Typography>
							</Box>
						);
					}}
				/>

				<TextField
					sx={{
						gridRow: '1/5',
						gridColumn: 2,
					}}
					inputProps={{
						style: { height: '100%' },
					}}
					fullWidth
					label='Beskrivning'
					multiline
					rows={8}
					value={formState.description}
					onChange={event => {
						setFormState({
							...formState,
							description: event.target.value,
						});
						setFormChanged(true);
					}}
				/>
			</Box>
		</>
	);
}
