import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	IconButton,
	TextField,
	Tooltip,
	Typography,
} from '@mui/material';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
	BagAllocation,
	StaffWithLock,
	TemplatesAndStaffContext,
} from '../FunctionSchedule';
import { Clear } from '@mui/icons-material';

type StaffNumAssignmentDialogProps = {
	open: boolean;
	setOpen: () => void;
	bag: BagAllocation | undefined;
	onSave: (
		nums: Array<{ staffID: number; assignedNum: number }>,
		comment: string
	) => void;
};

export function StaffNumAssignmentDialog(props: StaffNumAssignmentDialogProps) {
	const { staff } = useContext(TemplatesAndStaffContext);

	const [staffNum, setStaffNum] = useState<Array<number | undefined>>([]);
	const [functionComment, setFunctionComment] = useState<string>('');

	const staffList = useMemo(() => {
		if (props.bag)
			return props.bag.staffAllocation.map(stID =>
				staff.find(st => st.id === stID.id)
			) as StaffWithLock[];
		else return [];
	}, [props.bag, staff]);

	useEffect(() => {
		if (props.bag) {
			setStaffNum(props.bag.staffAllocation.map(st => st.assignedNum));
			setFunctionComment(props.bag.comment ?? '');
		} else {
			setStaffNum([]);
			setFunctionComment('');
		}
	}, [props.bag]);

	return (
		<Dialog open={props.open}>
			<DialogTitle>Redigera funktion</DialogTitle>
			<DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
				<Box>
					<Typography variant='body1'>Personalnumrering</Typography>
					<Box
						sx={{
							display: 'grid',
							gridTemplateColumns: 'auto auto',
							gap: 2,
						}}
					>
						{staffList.map((st, index) => (
							<React.Fragment key={st.id}>
								<Typography variant='body1'>{`${st.firstName} ${st.lastName}`}</Typography>
								<Box sx={{ alignItems: 'center' }}>
									<TextField
										value={staffNum[index] ?? ''}
										onChange={event => {
											const bs = [...staffNum];
											bs[index] = Number(event.target.value);
											setStaffNum(bs);
										}}
										label='Nummer'
										type='number'
										InputLabelProps={{
											shrink: true,
										}}
									/>
									<Tooltip title='Ta bort numrering'>
										<IconButton
											onClick={() => {
												const copy = [...staffNum];
												copy[index] = undefined;
												setStaffNum(copy);
											}}
										>
											<Clear />
										</IconButton>
									</Tooltip>
								</Box>
							</React.Fragment>
						))}
					</Box>
				</Box>
				<Divider />
				<Box>
					<Typography variant='body1'>Funktionskommentar</Typography>
					<TextField
						multiline
						fullWidth
						rows={4}
						value={functionComment}
						onChange={event => {
							setFunctionComment(event.target.value);
						}}
					/>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button onClick={props.setOpen}>Avbryt</Button>
				<Button
					onClick={() => {
						const staffWithNum = staffList
							.map((st, idx) => {
								return { staffID: st.id, assignedNum: staffNum[idx] };
							})
							.filter(
								(st): st is { staffID: number; assignedNum: number } =>
									st.assignedNum != null
							);

						props.setOpen();
						props.onSave(staffWithNum, functionComment);
					}}
				>
					Spara
				</Button>
			</DialogActions>
		</Dialog>
	);
}
