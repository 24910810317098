import { Close } from '@mui/icons-material';
import { IconButton, Slide, Snackbar, SnackbarContent } from '@mui/material';
import React, { useState } from 'react';

import { useTheme } from '@mui/material/styles';

export type MessageType = 'Success' | 'Info' | 'Warning' | 'Error';

export type SnackbarMessage = {
	messageType: MessageType;
	message: string;
};

type SnackbarContextType = {
	show: boolean;
	close: () => void;
	setShow: (message: SnackbarMessage) => void;
};

export const SnackbarContext = React.createContext<SnackbarContextType>(
	{} as SnackbarContextType
);

export const SnackbarProvider = (props: { children: React.ReactNode }) => {
	const [open, setOpen] = useState<boolean>(false);
	const [message, setMessage] = useState<SnackbarMessage>({
		messageType: 'Info',
		message: '',
	});

	const th = useTheme();

	const handleClose = (
		_event: React.SyntheticEvent | Event,
		reason?: string
	) => {
		if (reason === 'clickaway') return;
		setOpen(false);
	};

	const getBackground = () => {
		if (message.messageType === 'Success')
			return {
				backgroundColor: th.palette.success.main,
				color: th.palette.success.contrastText,
			};
		else if (message.messageType === 'Info')
			return {
				backgroundColor: th.palette.info.main,
				color: th.palette.info.contrastText,
			};
		else if (message.messageType === 'Warning')
			return {
				backgroundColor: th.palette.warning.main,
				color: th.palette.warning.contrastText,
			};
		else if (message.messageType === 'Error')
			return {
				backgroundColor: th.palette.error.main,
				color: th.palette.error.contrastText,
			};
		return { backgroundColor: undefined, color: 'inherit' };
	};

	return (
		<SnackbarContext.Provider
			value={{
				show: false,
				close: () => setOpen(false),
				setShow: (message: SnackbarMessage) => {
					setOpen(true);
					setMessage(message);
				},
			}}
		>
			<Slide in={open} direction='up'>
				<Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
					<SnackbarContent
						sx={getBackground()}
						message={message.message}
						action={
							<IconButton onClick={handleClose}>
								<Close sx={getBackground()} />
							</IconButton>
						}
					/>
				</Snackbar>
			</Slide>
			{props.children}
		</SnackbarContext.Provider>
	);
};
