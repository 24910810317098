import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import {
	Certificate,
	StaffScheduleTemplate,
	StaffWithProfession,
} from '../../../rest-models/rest-model';
import { FormButtonGroup, GenericFormProp, NameTextField } from '../Common';
import {
	Autocomplete,
	Box,
	Button,
	Chip,
	CircularProgress,
	Divider,
	FormLabel,
	IconButton,
	LinearProgress,
	Tab,
	Tabs,
	TextField,
	Tooltip,
	Typography,
} from '@mui/material';
import { BaseContext } from '../../../../context/BaseProvider';
import { StaffWithProfessionT } from '../ManagementPage';
import { Add, Check, Clear, Delete, Edit } from '@mui/icons-material';
import { restServer } from '../../../../services/AxiosConfiguration';
import { DesktopDatePicker, TimeField } from '@mui/x-date-pickers';
import { addYears, format } from 'date-fns';
import React from 'react';
import { setTime } from '../../../common/DateUtility';

type TabIndex = 'certs' | 'schedule';

type NewCert = {
	cert: Certificate | null;
	isTraining: boolean;
	startDate: Date;
	endDate: Date;
};

export function getFullName(staff: StaffWithProfession) {
	return `${staff.firstName} ${staff.lastName}`;
}

export const transformStaff = (
	staff: StaffWithProfession
): StaffWithProfessionT => {
	return {
		id: staff.id,
		name: getFullName(staff),
		description: '',
		// staff: { ...staff},
		staff: staff,
	};
};

const putFunction = (staff: StaffWithProfession) => {
	return restServer
		.put<StaffWithProfession>(`views/staff/${staff.id}`, staff)
		.then(res => res.data);
};

const deleteFunction = (staffID: number) => {
	return restServer
		.delete<StaffWithProfession>(`views/staff/${staffID}`)
		.then(res => res.data);
};

const postFunction = (staff: StaffWithProfession) => {
	return restServer
		.post<StaffWithProfession>('views/staff', staff)
		.then(res => res.data);
};

const StaffContext = createContext<{
	staffID: number;
}>({
	staffID: 0,
});

// Staff schedule setup

const DAY_NAMES = ['Måndag', 'Tisdag', 'Onsdag', 'Torsdag', 'Fredag'] as const;
const DAY_COLORS = [
	'#ffccff',
	'#ffff99',
	'#ffcc99',
	'#ccffff',
	'#eaeaea',
] as const;

type ScheduleContextType = {
	schedulesLoaded: boolean;
	fetchedSchedules: StaffScheduleTemplate[];
	schedules: StaffScheduleTemplate[];
	setSchedules: (val: StaffScheduleTemplate[]) => void;
	selectedSchedule: StaffScheduleTemplate | null;
	setSelectedSchedule: (val: StaffScheduleTemplate | null) => void;
};

const ScheduleContext = createContext<ScheduleContextType>({
	schedulesLoaded: false,
	fetchedSchedules: [],
	schedules: [],
	setSchedules: () => {},
	selectedSchedule: null,
	setSelectedSchedule: () => {},
});

const getSchedules = async (staffID: number) => {
	return restServer
		.get<StaffScheduleTemplate[]>('views/schedule-template', {
			params: { staffID },
		})
		.then(res => (res.status === 204 ? [] : res.data));
};

const postSchedule = async (schedule: StaffScheduleTemplate) => {
	return restServer
		.post(`views/schedule-template/`, schedule)
		.then(res => res.data);
};

const putSchedule = async (schedule: StaffScheduleTemplate) => {
	return restServer
		.put(`views/schedule-template/${schedule.scheduleTemplateID}`, schedule)
		.then(res => res.data);
};

const deleteSchedule = async (schedule: StaffScheduleTemplate) => {
	return restServer
		.delete(`views/schedule-template/${schedule.scheduleTemplateID}`)
		.then(res => res.data);
};

// Certificates

const DEFAULT_NEW_CERT: NewCert = {
	cert: null,
	isTraining: false,
	startDate: new Date(),
	endDate: addYears(new Date(), 3),
} as const;

const getCertificates = async () => {
	const res = await restServer.get<Certificate[]>('/views/certificate', {
		params: {
			sortBy: 'name',
		},
	});
	return res.data;
};

export function StaffForm(props: GenericFormProp<StaffWithProfessionT>) {
	const {
		selected,
		setSelected,
		formState,
		setFormState,
		formMode,
		setFormMode,
		formChanged,
		setFormChanged,
		shouldResetForm,
		setShouldResetForm,
		setRefetch,
	} = props;
	const { professionList } = useContext(BaseContext);

	const [addingCert, setAddingCert] = useState(false);
	useEffect(() => setAddingCert(false), [selected]);

	const [newCert, setNewCert] = useState<{
		cert: Certificate | null;
		isTraining: boolean;
		startDate: Date;
		endDate: Date;
	}>(DEFAULT_NEW_CERT);

	const [selectedTab, setSelectedTab] = useState<TabIndex>('certs');

	useEffect(() => {
		if (shouldResetForm) {
			setAddingCert(false);
			setNewCert(DEFAULT_NEW_CERT);
			setShouldResetForm(false);
			setSelectedSchedule(null);
		}
	}, [shouldResetForm, setShouldResetForm]);

	const [fetchedSchedules, setFetchedSchedules] = useState<
		StaffScheduleTemplate[]
	>([]);
	const [schedules, setSchedules] = useState<StaffScheduleTemplate[]>([]);
	const [selectedSchedule, setSelectedSchedule] =
		useState<StaffScheduleTemplate | null>(null);

	const [schedulesLoaded, setSchedulesLoaded] = useState(false);
	useEffect(() => {
		setSchedulesLoaded(false);
		setSelectedSchedule(null);
		getSchedules(selected.id).then(res => {
			setFetchedSchedules(res);
			setSchedules(structuredClone(res));
			setSchedulesLoaded(true);
		});
	}, [selected]);

	const [loadingSave, setLoadingSave] = useState(false);

	return (
		<Box className='management-form-inner' component='form'>
			<TextField
				sx={{ gridRow: 1, gridColumn: 1 }}
				variant='standard'
				label='Id'
				disabled
				value={props.selected.id}
			/>
			<Autocomplete
				sx={{ gridRow: 1, gridColumn: 2 }}
				size='medium'
				options={professionList}
				value={formState.staff.profession ?? null}
				isOptionEqualToValue={(opt, val) => val && opt.id === val.id}
				renderInput={params => (
					<TextField {...params} label='Yrke' variant='standard' />
				)}
				getOptionLabel={opt => opt.name}
				renderOption={(props, opt) => (
					<Typography {...props} key={`${opt.id}`}>
						{opt.name}
					</Typography>
				)}
				onChange={(_, val) => {
					setFormState({
						...formState,
						staff: {
							...formState.staff,
							profession: val ?? undefined,
						},
					});
					setFormChanged(true);
				}}
			/>

			<NameTextField
				label='Förnamn'
				sx={{
					gridRow: 2,
					gridColumn: 1,
				}}
				name={formState.staff.firstName}
				setName={firstName => {
					setFormState({
						...formState,
						staff: { ...formState.staff, firstName: firstName },
					});
					setFormChanged(true);
				}}
				disabled={formState.staff.firstName === selected.staff.firstName}
				reset={() =>
					setFormState({
						...formState,
						staff: {
							...formState.staff,
							firstName: selected.staff.firstName,
						},
					})
				}
			/>

			<NameTextField
				label='Efternamn'
				sx={{
					gridRow: 2,
					gridColumn: 2,
				}}
				name={formState.staff.lastName}
				setName={lastName => {
					setFormState({
						...formState,
						staff: { ...formState.staff, lastName: lastName },
					});
					setFormChanged(true);
				}}
				disabled={formState.staff.lastName === selected.staff.lastName}
				reset={() =>
					setFormState({
						...formState,
						staff: { ...formState.staff, lastName: selected.staff.lastName },
					})
				}
			/>

			<TextField
				sx={{ gridRow: 3, gridColumn: 1 }}
				variant='standard'
				label='Förkortning / Akronym'
				value={formState.staff.abbrev}
				onChange={event => {
					setFormState({
						...formState,
						staff: {
							...formState.staff,
							abbrev: event.target.value,
						},
					});
					setFormChanged(true);
				}}
			/>

			<Box gridColumn='span 2'>
				<Tabs
					value={selectedTab}
					onChange={(_, val) => setSelectedTab(val)}
					sx={{ gridRow: 4, mt: 4, mb: 1 }}
				>
					<Tab label={'Körkort'} value={'certs'} />
					<Tab label={'Schemamallar'} value={'schedule'} />
				</Tabs>

				{selectedTab === 'certs' && (
					<CertList
						addingCert={addingCert}
						setAddingCert={setAddingCert}
						newCert={newCert}
						setNewCert={setNewCert}
						formState={formState}
						setFormState={setFormState}
						setFormChanged={setFormChanged}
						setShouldResetForm={setShouldResetForm}
					/>
				)}
				<ScheduleContext.Provider
					value={{
						fetchedSchedules,
						schedules,
						setSchedules: val => {
							setSchedules(val);
							setFormChanged(true);
						},
						schedulesLoaded,
						selectedSchedule,
						setSelectedSchedule,
					}}
				>
					<StaffContext.Provider value={{ staffID: selected.staff.id }}>
						{selectedTab === 'schedule' && <Schedules />}
					</StaffContext.Provider>
				</ScheduleContext.Provider>
				{/* <Box gridRow={6}> */}
				<FormButtonGroup
					variant={formMode}
					hasChanged={formChanged}
					loading={loadingSave}
					reset={() => {
						setFormState(selected);
						const fSch = structuredClone(fetchedSchedules);
						setSchedules(fSch);
						if (selectedSchedule) {
							const found = fSch.find(
								(s: StaffScheduleTemplate) =>
									s.scheduleTemplateID === selectedSchedule.scheduleTemplateID
							);
							if (found) {
								setSelectedSchedule(found);
							}
						}
						setFormChanged(false);
						setShouldResetForm(true);
					}}
					save={() => {
						setLoadingSave(true);
						const putPromise = putFunction(formState.staff).then(res => {
							setSelected(structuredClone(formState));
						});

						const putSchedules = schedules.filter(s =>
							fetchedSchedules.some(
								fs => fs.scheduleTemplateID === s.scheduleTemplateID
							)
						);

						const postSchedules = schedules.filter(
							s =>
								!fetchedSchedules.some(
									fs => fs.scheduleTemplateID === s.scheduleTemplateID
								)
						);

						const putSchedulesPromise = Promise.all<
							Promise<StaffScheduleTemplate>
						>(putSchedules.map(putSchedule));

						const postSchedulesPromise = Promise.all<
							Promise<StaffScheduleTemplate>
						>(postSchedules.map(postSchedule));

						Promise.all([putSchedulesPromise, postSchedulesPromise]).then(
							proms => {
								const sorted = [...proms[0], ...proms[1]].sort(
									(a, b) => a.scheduleTemplateID - b.scheduleTemplateID
								);
								setFetchedSchedules(sorted);
								setSchedules(sorted);
							}
						);

						Promise.all([
							putPromise,
							putSchedulesPromise,
							postSchedulesPromise,
						]).then(() => {
							setFormChanged(false);
							setRefetch(true);
							setLoadingSave(false);
						});
					}}
					add={() => {
						setLoadingSave(true);
						postFunction(formState.staff).then(res => {
							Promise.all<Promise<StaffScheduleTemplate>>(
								schedules
									.map(s => ({ ...s, staffID: res.id }))
									.map(postSchedule)
							).then(scheds => {
								setFetchedSchedules(scheds);
								setSelected({ ...formState, id: res.id });
								setFormState({ ...formState, id: res.id });
								setFormMode('edit');
								setFormChanged(false);
								setRefetch(true);
								setLoadingSave(false);
							});
						});
					}}
					delete={() => {
						deleteFunction(selected.id).then(() => {
							setSelected(undefined);
							setFormState(undefined);
							setFormChanged(false);
							setRefetch(true);
						});
					}}
				/>
				{/* </Box> */}
			</Box>
		</Box>
	);
}

type CertListProps = {
	addingCert: boolean;
	setAddingCert: (val: boolean) => void;
	newCert: NewCert;
	setNewCert: (val: NewCert) => void;
	formState: StaffWithProfessionT;
	setFormState: (val: StaffWithProfessionT) => void;
	setFormChanged: (val: boolean) => void;
	setShouldResetForm: (val: boolean) => void;
};

function CertList(props: CertListProps) {
	const {
		addingCert,
		setAddingCert,
		newCert,
		setNewCert,
		formState,
		setFormState,
		setFormChanged,
		setShouldResetForm,
	} = props;

	const [certificates, setCertificates] = useState<Certificate[]>([]);
	useEffect(() => {
		getCertificates().then(res => setCertificates(res));
	}, []);

	return (
		<>
			<Box
				display={'flex'}
				flexDirection={'column'}
				alignItems={'start'}
				gap={0.5}
			>
				<CertListing
					formState={formState}
					setFormState={setFormState}
					setFormChanged={setFormChanged}
				/>

				<Box
					width='100%'
					display={!addingCert ? 'none' : 'flex'}
					alignItems='center'
					gap={1}
				>
					<Box flexBasis={300}>
						<FormLabel>Körkort</FormLabel>
						<Autocomplete
							size='medium'
							renderInput={props => <TextField {...props} variant='standard' />}
							getOptionLabel={opt => opt.name}
							options={certificates}
							value={newCert.cert}
							onChange={(_, value) => setNewCert({ ...newCert, cert: value })}
						/>
					</Box>
					<Box
						display='flex'
						flexDirection='column'
						alignItems='start'
						width={'10em'}
					>
						<FormLabel>Startdatum</FormLabel>
						<DesktopDatePicker
							disabled={newCert.isTraining}
							value={newCert.isTraining ? null : newCert.startDate}
							onAccept={newVal => {
								setNewCert({
									...newCert,
									startDate: newVal ?? newCert.startDate,
								});
							}}
						/>
					</Box>
					<Box
						display='flex'
						flexDirection='column'
						alignItems='start'
						width={'10em'}
					>
						<FormLabel>Slutdatum</FormLabel>
						<DesktopDatePicker
							disabled={newCert.isTraining}
							value={newCert.isTraining ? null : newCert.endDate}
							onAccept={newVal => {
								setNewCert({
									...newCert,
									endDate: newVal ?? newCert.endDate,
								});
							}}
						/>
					</Box>
					<Box display='flex' flexDirection='column' alignItems='start'>
						<FormLabel>Status</FormLabel>
						<Button
							sx={{ height: 40, alignSelf: 'center', mt: 'auto' }}
							color={newCert.isTraining ? 'warning' : 'success'}
							variant='contained'
							onClick={() => {
								setNewCert({ ...newCert, isTraining: !newCert.isTraining });
							}}
						>
							{!newCert.isTraining && 'Ej '}Upplärning
						</Button>
					</Box>
					<Button
						sx={{ height: 40, alignSelf: 'end' }}
						variant='contained'
						color='success'
						disabled={
							!(
								newCert.cert &&
								newCert.startDate &&
								newCert.endDate &&
								newCert.startDate <= newCert.endDate
							)
						}
						onClick={() => {
							setFormState({
								...formState,
								staff: {
									...formState.staff,
									certificate: newCert.cert
										? [
												...formState.staff.certificate,
												{
													...newCert.cert,
													isTraining: newCert.isTraining,
													startDate: format(
														newCert.startDate,
														'yyyy-MM-dd hh:mm:ss'
													) as unknown as Date,
													endDate: format(
														newCert.endDate,
														'yyyy-MM-dd hh:mm:ss'
													) as unknown as Date,
												},
										  ]
										: formState.staff.certificate,
								},
							});
						}}
					>
						Lägg till
					</Button>
					<Button
						sx={{ height: 40, alignSelf: 'end' }}
						variant='contained'
						color='error'
						onClick={() => {
							setAddingCert(false);
							setNewCert(DEFAULT_NEW_CERT);
							setShouldResetForm(false);
						}}
					>
						Avbryt
					</Button>
				</Box>
				<Button
					sx={{
						display: addingCert ? 'none' : 'flex',
						alignItems: 'center',
						p: 0,
						borderRadius: 0,
					}}
					startIcon={<Add />}
					onClick={() => {
						setAddingCert(true);
						setFormChanged(true);
					}}
				>
					Lägg till körkort
				</Button>
				<Divider sx={{ my: 1 }} />
			</Box>
		</>
	);
}

type CertListingProps = {
	formState: StaffWithProfessionT;
	setFormState: (val: StaffWithProfessionT) => void;
	setFormChanged: (val: boolean) => void;
};

function CertListing(props: CertListingProps) {
	const { formState, setFormState, setFormChanged } = props;

	const finalStaffCertificates = useMemo(() => {
		return formState.staff.certificate
			.map(c => ({
				...c,
				expired: !c.isTraining && new Date(c.endDate) < new Date(),
			}))
			.sort((a, b) => a.name.localeCompare(b.name));
		// .sort(a => -Number(!a.expired));
	}, [formState.staff.certificate]);

	const [editingRow, setEditingRow] = useState<number | null>(null);

	return (
		<Box
			display={'grid'}
			rowGap={-0.5}
			gridTemplateColumns={'12em 15em 11em 11em 5em 5em'}
			alignItems={'center'}
		>
			<Typography fontWeight={'bold'}>Metod</Typography>
			<Typography fontWeight={'bold'}>Utrustning</Typography>
			<Typography fontWeight={'bold'}>Startdatum</Typography>
			<Typography fontWeight={'bold'}>Slutdatum</Typography>
			<Typography fontWeight={'bold'}>Status</Typography>
			<Box />
			{finalStaffCertificates.map((cert, rowIdx) => (
				<Row
					key={`frag-${cert.id}`}
					cert={cert}
					setCert={val => {
						const copy = [...formState.staff.certificate];
						const foundIdx = copy.findIndex(c => c.id === cert.id);
						if (foundIdx === -1) {
							return;
						}
						copy[foundIdx] = { ...val };
						setFormState({
							...formState,
							staff: { ...formState.staff, certificate: copy },
						});
						setFormChanged(true);
					}}
					onDelete={() => {
						const copy = [...formState.staff.certificate];
						const spliceIdx = copy.findIndex(c => c.id === cert.id);
						copy.splice(spliceIdx, 1);
						setFormState({
							...formState,
							staff: { ...formState.staff, certificate: copy },
						});
						setFormChanged(true);
					}}
					editingRow={editingRow === rowIdx}
					setEditingRow={val => {
						if (val) {
							setEditingRow(rowIdx);
						} else {
							setEditingRow(null);
						}
					}}
				/>
			))}
		</Box>
	);
}

type RowProps = {
	cert: Certificate & { expired: boolean };
	setCert: (val: Certificate) => void;
	onDelete: () => void;
	editingRow: boolean;
	setEditingRow: (val: boolean) => void;
};

function Row(props: RowProps) {
	const { cert, setCert, onDelete, editingRow, setEditingRow } = props;

	const dateView = (date: Date, setDate: (val: Date) => void) => {
		return editingRow && !cert.isTraining ? (
			<DesktopDatePicker
				value={date}
				onAccept={val => val != null && setDate(val)}
			/>
		) : (
			<Typography color={cert.expired ? '#888' : undefined}>
				{cert.isTraining ? '-' : date.toDateString()}
			</Typography>
		);
	};

	return (
		<>
			<Typography color={cert.expired ? '#888' : undefined}>
				{cert.method?.name}
			</Typography>
			<Box color={cert.expired ? '#888' : undefined}>
				{cert.equipment
					.map(e => e.name)
					.map(n => (
						<Chip label={n} key={`chip-equip-${n}`} />
					))}
			</Box>
			{dateView(new Date(cert.startDate), val =>
				setCert({ ...cert, startDate: val })
			)}
			{dateView(new Date(cert.endDate), val =>
				setCert({ ...cert, endDate: val })
			)}
			{cert.expired ? (
				<Chip sx={{ my: '5px' }} color='error' label='Utgått' />
			) : cert.isTraining ? (
				<Chip sx={{ my: '5px' }} color='warning' label='Upplärning' />
			) : (
				<Box />
			)}
			<EditRowButtons
				editingRow={editingRow}
				setEditingRow={setEditingRow}
				onDelete={onDelete}
			/>
		</>
	);
}

type EditRowButtonsProps = {
	editingRow: boolean;
	setEditingRow: (val: boolean) => void;
	onDelete: () => void;
};

function EditRowButtons(props: EditRowButtonsProps) {
	const { editingRow, setEditingRow, onDelete } = props;

	return (
		<>
			{!editingRow ? (
				<IconButton
					sx={{
						justifySelf: 'center',
						':hover': { background: '#0002' },
					}}
					onClick={() => setEditingRow(true)}
				>
					<Edit />
				</IconButton>
			) : (
				<Box
					justifySelf={'center'}
					visibility={editingRow ? undefined : 'hidden'}
				>
					<IconButton
						color='error'
						sx={{ ':hover': { background: '#F005' } }}
						onClick={() => {
							onDelete();
							setEditingRow(false);
						}}
					>
						<Delete />
					</IconButton>
					<IconButton
						sx={{ ':hover': { background: '#0002' } }}
						onClick={() => setEditingRow(false)}
					>
						<Clear />
					</IconButton>
				</Box>
			)}
		</>
	);
}

function Schedules() {
	const {
		fetchedSchedules,
		schedules,
		setSchedules,
		schedulesLoaded,
		selectedSchedule,
		setSelectedSchedule,
	} = useContext(ScheduleContext);

	const { staffID } = useContext(StaffContext);

	const [editing, setEditing] = useState<{ week: number; day: number } | null>(
		null
	);

	const [scheduleName, setScheduleName] = useState<string>('');
	const [loadingApply, setLoadingApply] = useState(false);
	const [startDate, setStartDate] = useState<Date | null>(new Date());
	const [endDate, setEndDate] = useState<Date | null>(null);

	const weeks = selectedSchedule?.template?.map((week, weekIdx) => {
		const days = Array(5)
			.fill(0)
			.map((_, idx) => {
				const day = week.find(d => d.dayIndex === idx);
				return day ?? null;
			});
		return (
			<>
				{days.map((day, dayIdx) => {
					const editingThisDay =
						editing?.week === weekIdx && editing.day === dayIdx;
					return day ? (
						<Box
							key={dayIdx}
							display={'grid'}
							gridTemplateColumns={'4em 2em 4em 1fr'}
							alignItems={'center'}
							sx={{ background: DAY_COLORS[day.dayIndex], p: 1, pl: 2 }}
						>
							<Typography fontWeight={'bold'}>Start</Typography>
							<Typography fontWeight={'bold'} />
							<Typography fontWeight={'bold'}>Slut</Typography>
							{!editingThisDay ? (
								<Typography>
									{day.startTime.split(':').slice(0, 2).join(':')}
								</Typography>
							) : (
								<TimeField
									defaultValue={setTime(new Date(), day.startTime)}
									onChange={val => {
										if (!val) {
											return;
										}
										const sched = schedules.find(
											sch =>
												sch.scheduleTemplateID ===
												selectedSchedule.scheduleTemplateID
										);
										const foundDay = sched?.template[weekIdx].find(
											d => d.dayIndex === day.dayIndex
										);
										if (foundDay != null) {
											foundDay.startTime = val
												? format(val, 'HH:mm:ss')
												: foundDay.startTime;
											setSchedules([...schedules]);
										}
									}}
									inputProps={{ sx: { px: 1.4, py: 0.8 } }}
								/>
							)}
							<Typography
								sx={{ justifySelf: editingThisDay ? 'center' : undefined }}
							>
								-
							</Typography>
							{!editingThisDay ? (
								<Typography>
									{day.endTime.split(':').slice(0, 2).join(':')}
								</Typography>
							) : (
								<TimeField
									format='HH:mm'
									defaultValue={setTime(new Date(), day.endTime)}
									onChange={val => {
										if (!val) {
											return;
										}
										const sched = schedules.find(
											sch =>
												sch.scheduleTemplateID ===
												selectedSchedule.scheduleTemplateID
										);
										const foundDay = sched?.template[weekIdx].find(
											d => d.dayIndex === day.dayIndex
										);
										if (foundDay != null) {
											foundDay.endTime = val
												? format(val, 'HH:mm:ss')
												: foundDay.endTime;
											setSchedules([...schedules]);
										}
									}}
									inputProps={{ sx: { px: 1.4, py: 0.8 } }}
								/>
							)}
							<Box
								gridColumn={4}
								gridRow={'1 / span 2'}
								width={'max-content'}
								display={'flex'}
								flexDirection={'column'}
								justifySelf={'end'}
							>
								{!editing ? (
									<>
										<IconButton
											onClick={() => {
												setEditing({ week: weekIdx, day: dayIdx });
											}}
										>
											<Edit />
										</IconButton>
										<IconButton
											onClick={() => {
												const sched = schedules.find(
													sch =>
														sch.scheduleTemplateID ===
														selectedSchedule.scheduleTemplateID
												);
												if (sched == null) {
													return;
												}
												const idx = sched.template[weekIdx].findIndex(
													d => d.dayIndex === day.dayIndex
												);
												if (idx === -1) {
													return;
												}
												sched.template[weekIdx].splice(idx, 1);
												setSchedules([...schedules]);
												setSelectedSchedule(sched);
											}}
										>
											<Delete />
										</IconButton>
									</>
								) : (
									<>
										<IconButton
											sx={{
												visibility: editingThisDay ? 'visible' : 'hidden',
											}}
											onClick={() => setEditing(null)}
										>
											<Check />
										</IconButton>
										<IconButton sx={{ visibility: 'hidden' }}>
											<Check />
										</IconButton>
									</>
								)}
							</Box>
						</Box>
					) : (
						<Button
							key={dayIdx}
							onClick={() => {
								const sched = schedules.find(
									sch =>
										sch.scheduleTemplateID ===
										selectedSchedule.scheduleTemplateID
								);
								if (sched == null) {
									return;
								}
								sched.template[weekIdx].push({
									dayIndex: dayIdx,
									startTime: '07:30:00',
									endTime: '16:00:00',
								});
								setSchedules([...schedules]);
							}}
						>
							<Add />
						</Button>
					);
				})}
			</>
		);
	});

	const scheduleView = (
		<>
			<Box
				sx={{
					display: 'flex',
					gap: 2,
					alignItems: 'center',
					mb: 2,
				}}
			>
				<Autocomplete
					noOptionsText='Inga schemamallar'
					sx={{ width: '32ch' }}
					renderInput={params => (
						<TextField variant={'standard'} label={'Schemamall'} {...params} />
					)}
					value={selectedSchedule}
					options={schedules}
					getOptionLabel={opt => `${opt.description}`}
					isOptionEqualToValue={(a, b) =>
						a.scheduleTemplateID === b.scheduleTemplateID
					}
					onChange={(_, val) => {
						setSelectedSchedule(val);
						setScheduleName(val != null ? val.description : scheduleName);
					}}
				/>
				<Divider orientation='vertical' variant='middle' flexItem />
				<Button
					startIcon={<Add />}
					onClick={() => {
						const newItem = {
							staffID: staffID,
							scheduleTemplateID: 0,
							description: '',
							template: [],
						};
						setSchedules([...schedules, newItem]);
						setSelectedSchedule(newItem);
						setScheduleName(newItem.description);
					}}
				>
					Skapa ny schemamall
				</Button>
			</Box>

			{selectedSchedule && (
				<Box
					sx={{
						borderTopColor: t => t.palette.divider,
						borderTop: '1px solid',
						p: 2,
					}}
				>
					<Box sx={{ display: 'flex', mb: 2 }}>
						<TextField
							label={'Namn på mall'}
							value={scheduleName}
							onChange={event => setScheduleName(event.target.value)}
							sx={{ width: '64ch' }}
						/>
						<Tooltip title={'Sätt namn'}>
							<IconButton
								onClick={() => {
									selectedSchedule.description = scheduleName;
									setSchedules([...schedules]);
								}}
							>
								<Check />
							</IconButton>
						</Tooltip>
						<Tooltip title={'Ta bort mall'}>
							<IconButton
								onClick={() => {
									const scheduleID = selectedSchedule.scheduleTemplateID;
									const schedIdx = schedules.findIndex(
										s => s.scheduleTemplateID === scheduleID
									);
									if (schedIdx === -1) {
										return;
									}
									schedules.splice(schedIdx, 1);
									setSchedules([...schedules]);
									if (
										fetchedSchedules.some(
											fs => fs.scheduleTemplateID === scheduleID
										)
									) {
										deleteSchedule(selectedSchedule);
									}
									setSelectedSchedule(null);
								}}
							>
								<Delete />
							</IconButton>
						</Tooltip>
					</Box>
					<Box
						sx={{
							display: 'grid',
							gridTemplateColumns: 'auto repeat(5, 1fr)',
							gap: 1,
						}}
					>
						<Typography fontWeight={'bold'}>Vecka</Typography>
						{DAY_NAMES.map(dayName => (
							<Typography fontWeight={'bold'} key={dayName}>
								{dayName}
							</Typography>
						))}
						{weeks?.map((week, idx) => (
							<React.Fragment key={idx}>
								<Box display={'flex'} alignItems={'center'}>
									<Typography sx={{ width: 'max-content' }}>
										v. {idx + 1}
									</Typography>
									<IconButton
										sx={{ aspectRatio: 1, height: 35, justifySelf: 'center' }}
										onClick={() => {
											const sched = schedules.find(
												sch =>
													sch.scheduleTemplateID ===
													selectedSchedule.scheduleTemplateID
											);
											if (sched) {
												sched.template.splice(idx, 1);
												setSchedules([...schedules]);
											}
										}}
									>
										<Delete />
									</IconButton>
								</Box>
								{week}
							</React.Fragment>
						))}
						<IconButton
							sx={{ aspectRatio: 1, height: 35, justifySelf: 'center' }}
							onClick={() => {
								const sched = schedules.find(
									sch =>
										sch.scheduleTemplateID ===
										selectedSchedule.scheduleTemplateID
								);
								if (sched) {
									sched.template.push([]);
									setSchedules([...schedules]);
								}
							}}
						>
							<Add />
						</IconButton>
					</Box>

					{/* Apply */}
					<Divider sx={{ mb: 2 }}>
						<Typography sx={{ color: '#888' }} variant='button'>
							Applicera schemamall
						</Typography>
					</Divider>

					<Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
						<DesktopDatePicker
							label={'Startdatum'}
							value={startDate}
							onChange={val => {
								setStartDate(val);
							}}
						/>
						<DesktopDatePicker
							label={'Slutdatum'}
							value={endDate}
							onChange={val => {
								setEndDate(val);
							}}
						/>
						<Tooltip
							title={'Applicera mall på datumintervall'}
							sx={{ gridRow: 3 }}
						>
							<IconButton
								onClick={() => {
									if (!(startDate && endDate)) {
										return;
									}
									setLoadingApply(true);
									restServer
										.post(
											`/script/schedule-template/${selectedSchedule.scheduleTemplateID}`,
											undefined,
											{
												params: {
													staffID: staffID,
													startDate: format(startDate, 'yyyy-MM-dd'),
													endDate: format(endDate, 'yyyy-MM-dd'),
												},
											}
										)
										.then(() => {
											setLoadingApply(false);
										});
								}}
							>
								<Check />
							</IconButton>
						</Tooltip>
						{loadingApply && <CircularProgress size={20} />}
					</Box>
				</Box>
			)}
		</>
	);

	return (
		<Box sx={{ minHeight: '50vh' }}>
			{schedulesLoaded ? scheduleView : <LinearProgress />}
		</Box>
	);
}
