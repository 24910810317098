import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { sv } from 'date-fns/locale';
import { Route, Routes } from 'react-router-dom';
import { AppThemeProvider } from './context/AppThemeProvider';
import { BaseProvider } from './context/BaseProvider';
import React, { useEffect } from 'react';
import { setDefaultOptions } from 'date-fns';
import { ReactKeycloakProvider, useKeycloak } from '@react-keycloak/web';
import keycloak from './services/auth';
import BaseLayout from './components/layouts/BaseLayout';
import PageNotFound from './components/pages/PageNotFound';
import { ManagementPage } from './components/pages/management/ManagementPage';
import { AxiosSetup } from './services/AxiosConfiguration';
import { FunctionSchedulePage } from './components/pages/FunctionSchedule';
import { SchedulePage } from './components/pages/SchedulePage';
import { SnackbarProvider } from './context/SnackbarProvider';
import { StaffSchedulePage } from './components/pages/StaffSchedulePage';

function ProtectedWrapper({ children }: { children: JSX.Element }) {
	const { keycloak } = useKeycloak();

	if (!keycloak.authenticated) return <></>;

	return children;
}

function App() {
	useEffect(() => {
		setDefaultOptions({ locale: sv });
		AxiosSetup(keycloak);
	}, []);

	return (
		<ReactKeycloakProvider
			authClient={keycloak}
			initOptions={{
				onLoad: 'login-required',
				checkLoginIframe: false,
			}}
		>
			<React.StrictMode>
				<ProtectedWrapper>
					<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={sv}>
						<AppThemeProvider>
							<BaseProvider>
								<SnackbarProvider>
									<Routes>
										<Route path='/' element={<BaseLayout />}>
											<Route
												path='function-schedule'
												element={<FunctionSchedulePage />}
											/>
											<Route
												path='staff-schedule'
												element={<StaffSchedulePage />}
											/>
											<Route
												path='staff-schedule2'
												element={<StaffSchedulePage />}
											/>
											<Route path='schedule' element={<SchedulePage />} />
											<Route path='management' element={<ManagementPage />} />
											<Route path='*' element={<PageNotFound />} />
										</Route>
									</Routes>
								</SnackbarProvider>
							</BaseProvider>
						</AppThemeProvider>
					</LocalizationProvider>
				</ProtectedWrapper>
			</React.StrictMode>
		</ReactKeycloakProvider>
	);
}

export default App;
