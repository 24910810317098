import { useEffect, useState } from 'react';
import { restServer } from '../../../../services/AxiosConfiguration';
import {
	Certificate,
	CertificateStaff,
	CertificateWithStaff,
	Equipment,
	Method,
} from '../../../rest-models/rest-model';
import { GenericFormProp, NameTextField } from '../Common';
import {
	Autocomplete,
	Box,
	Chip,
	Divider,
	TextField,
	Typography,
} from '@mui/material';
import React from 'react';

const getMethods = async () => {
	const res = await restServer.get<Method[]>('/method', {
		params: { sortBy: 'name' },
	});
	return res.data;
};

const getEquipment = async () => {
	const res = await restServer.get<Equipment[]>('/equipment', {
		params: { sortBy: 'name' },
	});
	return res.data;
};

const getStaff = async (certificateID: number) => {
	const res = await restServer.get<CertificateWithStaff>(
		`/views/certificate-staff/${certificateID}`,
		{
			params: { sortBy: 'name' },
		}
	);
	return res.data;
};

export function CertificateForm(props: GenericFormProp<Certificate>) {
	const { selected, formState, setFormState, setFormChanged } = props;

	const [methods, setMethods] = useState<Method[]>([]);
	const [equipment, setEquipment] = useState<Equipment[]>([]);
	const [staff, setStaff] = useState<CertificateStaff[]>([]);
	useEffect(() => {
		getMethods().then(setMethods);
	}, []);
	useEffect(() => {
		getEquipment().then(setEquipment);
	}, []);
	useEffect(() => {
		getStaff(selected.id).then(res => setStaff(res.staff));
	}, [selected.id]);

	// console.log(methods, equipment, methodSelection, equipmentSelection);

	return (
		<Box className='management-form-inner' component='form'>
			<TextField
				sx={{ gridRow: 1, gridColumn: 1 }}
				variant='standard'
				label='Id'
				disabled
				value={props.selected.id}
			/>

			<NameTextField
				sx={{
					gridRow: 2,
					gridColumn: 1,
				}}
				name={formState.name}
				setName={name => {
					setFormState({ ...props.selected, name });
					setFormChanged(true);
				}}
				disabled={selected.name === formState.name}
				reset={() => setFormState({ ...formState, name: selected.name })}
			/>

			<Autocomplete
				sx={{ gridRow: 3, gridColumn: 1 }}
				size='medium'
				options={methods}
				value={formState.method ?? null}
				isOptionEqualToValue={(opt, val) => val && opt.id === val.id}
				renderInput={params => (
					<TextField {...params} label='Metod' variant='standard' />
				)}
				getOptionLabel={opt => opt.name}
				renderOption={(props, opt) => (
					<Typography {...props} key={`${opt.id}`}>
						{opt.name}
					</Typography>
				)}
				onChange={(_, val) => {
					setFormState({ ...formState, method: val ?? undefined });
					setFormChanged(true);
				}}
			/>
			<Autocomplete
				sx={{ gridRow: 4, gridColumn: 1 }}
				multiple
				size='medium'
				options={equipment}
				value={formState.equipment}
				isOptionEqualToValue={(opt, val) => val && opt.id === val.id}
				renderInput={params => (
					<TextField {...params} label='Utrustning' variant='standard' />
				)}
				getOptionLabel={opt => opt.name}
				renderOption={(props, opt) => (
					<Typography {...props} key={`${opt.id}`}>
						{opt.name}
					</Typography>
				)}
				onChange={(_, val) => {
					setFormState({ ...formState, equipment: val ?? formState.equipment });
					setFormChanged(true);
				}}
			/>

			<TextField
				sx={{
					gridColumn: 2,
					gridRow: 'span 4',
				}}
				inputProps={{ style: { height: '100%' } }}
				label='Beskrivning'
				fullWidth
				multiline
				rows={4}
				value={formState.description ?? ''}
				onChange={event => {
					setFormState({
						...formState,
						description: event.target.value,
					});
					setFormChanged(true);
				}}
			/>

			{staff.length > 0 && (
				<Box
					mt={2}
					display={'grid'}
					gridColumn={1}
					rowGap={0.5}
					gridTemplateColumns={'120px 150px 150px 150px 150px 100px'}
				>
					<Typography
						variant={'h6'}
						fontWeight={'bold'}
						sx={{ gridColumn: 'span 6' }}
					>
						Personal med körkort
					</Typography>
					<Divider sx={{ gridColumn: 'span 6' }} />
					<Typography fontWeight={'bold'}>Förkortning</Typography>
					<Typography fontWeight={'bold'}>Förnamn</Typography>
					<Typography fontWeight={'bold'}>Efternamn</Typography>
					<Typography fontWeight={'bold'}>Startdatum</Typography>
					<Typography fontWeight={'bold'}>Slutdatum</Typography>
					<Typography fontWeight={'bold'}>Upplärningsstatus</Typography>
					{staff.map(st => (
						<React.Fragment key={st.id}>
							<Typography>{st.abbrev}</Typography>
							<Typography>{st.firstName}</Typography>
							<Typography>{st.lastName}</Typography>
							<Typography>{st.startDate}</Typography>
							<Typography>{st.endDate}</Typography>
							{st.isTraining ? (
								<Chip color={'warning'} label={'Upplärning'} />
							) : (
								<Box />
							)}
						</React.Fragment>
					))}
				</Box>
			)}
		</Box>
	);
}
