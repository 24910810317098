import {
	Box,
	Checkbox,
	Chip,
	FormControlLabel,
	LinearProgress,
	Paper,
	Tab,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tabs,
	Typography,
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { format } from 'date-fns';
import React, { useEffect, useMemo, useState } from 'react';
import {
	Equipment,
	FunctionSchedule,
	FunctionScheduleBag,
	StaffWithProfession,
} from '../rest-models/rest-model';
import { restServer } from '../../services/AxiosConfiguration';
import { WeekTab } from './Schedule/WeekTab';

export type EquipmentWithRank = Equipment & { rank: number };

const chipAmPm = (startTime: string, endTime: string) => {
	let bgColor = '#eee';
	let label = undefined;
	if (startTime >= '07:00' && endTime <= '12:30') {
		label = 'FM';
		bgColor = '#2e7d32';
	} else if (startTime >= '12:00' && endTime <= '16:30') {
		bgColor = '#ed6c02';
		label = 'EM';
	}

	return (
		<Chip sx={{ backgroundColor: bgColor, color: '#fff' }} label={label} />
	);
};

export function SchedulePage() {
	const [date, setDate] = useState(new Date());
	const [tabValue, setTabValue] = useState(0);
	return (
		<>
			<Box
				sx={{
					display: 'flex',
					alignItems: 'start',
					justifyContent: 'space-between',
					mb: 2,
				}}
			>
				<Typography variant='h3'>Schema</Typography>
				<Paper>
					<DesktopDatePicker
						sx={{ p: '1px' }}
						// label={'Datum'}
						value={date}
						onChange={val => setDate(val ?? date)}
					/>
				</Paper>
			</Box>

			<Tabs
				component={Paper}
				sx={{ borderBottom: 1 }}
				value={tabValue}
				onChange={(_event: React.SyntheticEvent, newValue: number) => {
					setTabValue(newValue);
				}}
			>
				<Tab label='Veckovy' />
				<Tab label='Dagsvy' />
			</Tabs>
			<div role='tabpanel'>
				{tabValue === 0 && (
					<WeekTab
						date={date}
						changeDate={newDate => {
							setDate(newDate);
							setTabValue(1);
						}}
					/>
				)}
			</div>
			<div role='tabpanel'>{tabValue === 1 && <DayTab date={date} />}</div>
		</>
	);
}

export type EquipmentAndBag = {
	equipment: EquipmentWithRank;
	bags: FunctionScheduleBag[];
	canBeHidden: boolean;
};

function DayTab(props: { date: Date }) {
	const [dataLoading, setDataLoading] = useState(true);
	const [checkShowAll, setCheckShowAll] = useState<boolean>(false);
	const [content, setContent] = useState<EquipmentAndBag[]>([]);

	useEffect(() => {
		const dateString = format(props.date, 'yyyy-MM-dd');

		restServer<FunctionSchedule>(`views/function-schedule/${dateString}`)
			.then(res => {
				const allEquipment = res.data.jsonData.bags
					.reduce<EquipmentWithRank[]>((acc, cur) => {
						const findResult = acc.find(item => item.id === cur.equipment.id);
						if (findResult === undefined) {
							// get rank from template
							const templateItem = res.data.jsonData.template.row.find(
								p => p.equipment?.id === cur.equipment.id
							);
							if (templateItem !== undefined) {
								acc.push({ ...cur.equipment, rank: templateItem.rank });
							} else {
								acc.push({ ...cur.equipment, rank: 100 });
							}
						}
						return acc;
					}, [])
					.sort((a, b) => a.rank - b.rank);

				const content: EquipmentAndBag[] = allEquipment.map(equipment => {
					const find = res.data.jsonData.bags.filter(
						p => p.equipment.id === equipment.id
					);
					const numLocked = find.reduce<number>((acc, cur) => {
						if (cur.locked === true) acc += 1;
						return acc;
					}, 0);
					if (find.length > 0) {
						return {
							equipment: equipment,
							bags: find.sort((a, b) => a.startTime.localeCompare(b.startTime)),
							canBeHidden: numLocked === find.length,
						};
					} else return { equipment: equipment, bags: [], canBeHidden: true };
				});

				setContent(content);
				setDataLoading(false);
			})
			.catch(err => {
				setContent([]);
				setDataLoading(false);
			});
	}, [props.date]);

	const maxChar = useMemo(() => {
		if (content.length > 0) {
			return content.reduce<number>((acc, cur) => {
				if (cur.equipment.name.length > acc) acc = cur.equipment.name.length;
				return acc;
			}, content[0].equipment.name.length);
		} else return 32;
	}, [content]);

	const drawFunction = (bag: FunctionScheduleBag) => {
		return (
			<Box
				key={bag.dropListID}
				sx={{
					display: 'flex',
					alignItems: 'start',
					gap: 1,
					'.can-be-cancelled': {
						textDecoration: bag.locked === true ? 'line-through' : undefined,
					},
				}}
			>
				<Typography
					variant='body1'
					fontWeight='bold'
					className='can-be-cancelled'
					flexShrink={0}
					// noWrap
				>
					{bag.startTime} - {bag.endTime}
				</Typography>
				{chipAmPm(bag.startTime, bag.endTime)}
				{bag.locked === true ? (
					<Typography variant='body1'>Spärrad</Typography>
				) : undefined}
				{bag.staffAllocation.length === 0 ? (
					<Typography variant='body1' fontStyle='italic' color='#ccc'>
						Ingen personal
					</Typography>
				) : (
					<Box>{staffToName(bag.staffAllocation)}</Box>
				)}
			</Box>
		);
	};

	return (
		<Paper sx={{ p: 2, minHeight: 800 }}>
			{dataLoading === true ? (
				<LinearProgress sx={{ mt: 2 }} />
			) : content.length === 0 ? (
				<Typography variant='body1'>
					Inget funktionsschema för {format(props.date, 'yyyy-MM-dd')}
				</Typography>
			) : (
				<>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between',
						}}
					>
						<Typography variant='body1'>
							{format(props.date, 'EEEE, yyyy-MM-dd')}
						</Typography>
						<FormControlLabel
							control={
								<Checkbox
									checked={checkShowAll}
									onChange={event => setCheckShowAll(event.target.checked)}
								/>
							}
							label='Visa all utrustning'
						/>
					</Box>
					<TableContainer>
						<Table
							size='small'
							sx={{
								'.firstCol': {
									width: `${maxChar + 2}ch`,
								},
								'.MuiTableBody-root': {
									'.odd': {
										backgroundColor: '#ddd',
									},
									'.MuiTableRow-root:hover': {
										backgroundColor: t => t.palette.primary.light,
									},
								},
							}}
						>
							<TableHead>
								<TableRow
									sx={{
										backgroundColor: t => t.palette.primary.main,
									}}
								>
									<TableCell className='firstCol'>
										<Typography
											variant='subtitle1'
											fontWeight='bold'
											sx={{
												color: t => t.palette.primary.contrastText,
											}}
										>
											Utrustning
										</Typography>
									</TableCell>
									<TableCell>
										<Typography
											variant='subtitle1'
											fontWeight='bold'
											sx={{ color: t => t.palette.primary.contrastText }}
										>
											Funktioner & Personal
										</Typography>
									</TableCell>
									<TableCell>
										<Typography
											variant='subtitle1'
											fontWeight='bold'
											sx={{ color: t => t.palette.primary.contrastText }}
										>
											Kommentar
										</Typography>
									</TableCell>
								</TableRow>
							</TableHead>

							<TableBody>
								{content
									.filter(
										item =>
											!(item.canBeHidden === true && checkShowAll === false)
									)
									.map(({ equipment, bags }, index) => {
										const bagsToShow =
											checkShowAll === false
												? bags.filter(p => p.locked === false)
												: bags;
										const evenOddClassName = index % 2 === 0 ? 'even' : 'odd';

										return (
											<React.Fragment key={equipment.id}>
												<TableRow
													key={equipment.id}
													className={evenOddClassName}
												>
													<TableCell rowSpan={bagsToShow.length + 1}>
														<Typography variant='body1' fontWeight='bold'>
															{equipment.name}
														</Typography>
													</TableCell>
												</TableRow>
												{bags.length === 0 ? (
													<TableRow className={evenOddClassName}>
														<TableCell></TableCell>
													</TableRow>
												) : (
													bagsToShow.map(bag => (
														<TableRow
															key={bag.dropListID}
															className={evenOddClassName}
														>
															<TableCell>{drawFunction(bag)}</TableCell>
															<TableCell>{bag.comment}</TableCell>
														</TableRow>
													))
												)}
											</React.Fragment>
										);
									})}
							</TableBody>
						</Table>
					</TableContainer>
				</>
			)}
		</Paper>
	);
}

export interface StaffWithNumAssignment extends StaffWithProfession {
	numAssignment?: number;
}

/**
 * Converts a list of staff to a comma separated string Removes trailing ", "
 * @param staffList StaffWithProfession[]
 */
const staffToName = (
	staffList: StaffWithNumAssignment[],
	fullName: boolean = true
) => {
	return (
		<Box sx={{ display: 'flex', alignItems: 'start' }}>
			{staffList.map(st => (
				<React.Fragment key={st.id}>
					{st.numAssignment && <Chip label={st.numAssignment} size='small' />}
					<Typography variant='body1' sx={{ mr: 1 }}>
						{fullName === true
							? `${st.firstName} ${st.lastName}`
							: `${st.abbrev}`}
					</Typography>
				</React.Fragment>
			))}
		</Box>
	);
};
