import { restServer } from '../../services/AxiosConfiguration';
import { DataModel } from '../pages/management/GenericComponent';
import {
	Method,
	Equipment,
	Certificate,
	Profession,
	StaffWithProfession,
} from './rest-model';

export const methodModel: DataModel<Method> = {
	getFunction: (): Promise<Method[]> =>
		restServer
			.get<Method[]>('/views/method', { params: { sortBy: 'name' } })
			.then(res => res.data),
	postFunction: (newItem: Method): Promise<Method> =>
		restServer.post<Method>('/views/method', newItem).then(res => res.data),
	deleteFunction: (methodID: number) =>
		restServer
			.delete<Method>(`/views/method/${methodID}`)
			.then(res => res.data),
	putFunction: (method: Method) =>
		restServer
			.put<Method>(`/views/method/${method.id}`, method)
			.then(res => res.data),
};

export const equipmentModel: DataModel<Equipment> = {
	getFunction: (): Promise<Equipment[]> =>
		restServer
			.get<Equipment[]>('/equipment', { params: { sortBy: 'name' } })
			.then(res => res.data),
	postFunction: (equipment: Equipment): Promise<Equipment> =>
		restServer.post<Equipment>('/equipment', equipment).then(res => res.data),
	deleteFunction: (equipmentID: number): Promise<Equipment> =>
		restServer
			.delete<Equipment>(`/equipment/${equipmentID}`)
			.then(res => res.data),
	putFunction: (equipment: Equipment): Promise<Equipment> =>
		restServer
			.put<Equipment>(`/equipment/${equipment.id}`, equipment)
			.then(res => res.data),
};

export const certificateModel: DataModel<Certificate> = {
	getFunction: (): Promise<Certificate[]> =>
		restServer
			.get<Certificate[]>('/views/certificate', {
				params: {
					sortBy: 'name',
				},
			})
			.then(res => res.data),
	postFunction: (certificate: Certificate): Promise<Certificate> =>
		restServer
			.post<Certificate>('/views/certificate', certificate)
			.then(res => res.data),
	deleteFunction: (certificateID: number): Promise<Certificate> =>
		restServer
			.delete<Certificate>(`/views/certificate/${certificateID}`)
			.then(res => res.data),
	putFunction: (certificate: Certificate): Promise<Certificate> =>
		restServer
			.put<Certificate>(`/views/certificate/${certificate.id}`, certificate)
			.then(res => res.data),
};

export const professionModel: DataModel<Profession> = {
	getFunction: (): Promise<Profession[]> =>
		restServer
			.get<Profession[]>('/profession', {
				params: {
					sortBy: 'name',
				},
			})
			.then(res => res.data),

	postFunction: (profession: Profession): Promise<Profession> =>
		restServer
			.post<Profession>('/profession', profession)
			.then(res => res.data),

	deleteFunction: (professionID: number): Promise<Profession> =>
		restServer
			.delete<Profession>(`/profession/${professionID}`)
			.then(res => res.data),

	putFunction: (profession: Profession): Promise<Profession> =>
		restServer
			.put<Profession>(`/profession/${profession.id}`, profession)
			.then(res => res.data),
};

export const staffModel: DataModel<StaffWithProfession> = {
	getFunction: (): Promise<StaffWithProfession[]> =>
		restServer
			.get<StaffWithProfession[]>('views/staff', {
				params: {
					sortBy: 'name',
				},
			})
			.then(res => res.data),

	putFunction: (staff: StaffWithProfession): Promise<StaffWithProfession> =>
		restServer
			.put<StaffWithProfession>(`views/staff/${staff.id}`, staff)
			.then(res => res.data),

	deleteFunction: (staffID: number): Promise<StaffWithProfession> =>
		restServer
			.delete<StaffWithProfession>(`views/staff/${staffID}`)
			.then(res => res.data),

	postFunction: (staff: StaffWithProfession): Promise<StaffWithProfession> =>
		restServer
			.post<StaffWithProfession>('views/staff', staff)
			.then(res => res.data),
};

export interface TemplateWeekDay {
	dayIndex: number;
	rank: number;
	startTime: string;
	endTime: string;
	description: string;
	minSlot: number;
	maxSlot: number;
	certificates: (Certificate | null)[];
}

export interface FunctionTemplateRow {
	equipment: Equipment | null;
	rank: number;
	week: Array<TemplateWeekDay>;
}

export interface FunctionTemplate {
	id: number;
	name: string;
	description: string;
	row: FunctionTemplateRow[];
}

export const functionModel: DataModel<FunctionTemplate> = {
	getFunction: async () =>
		await restServer
			.get<FunctionTemplate[]>('views/function-template', {
				params: { sortBy: 'name' },
			})
			.then(res => res.data),

	postFunction: async (newItem: FunctionTemplate) =>
		await restServer
			.post<FunctionTemplate>('views/function-template', newItem)
			.then(res => res.data),

	deleteFunction: async (index: number) =>
		await restServer
			.delete<FunctionTemplate>(`views/function-template/${index}`)
			.then(res => res.data),

	putFunction: async (newItem: FunctionTemplate) =>
		await restServer
			.put<FunctionTemplate>(`views/function-template/${newItem.id}`, newItem)
			.then(res => res.data),
};
