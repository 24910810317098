import {
	Box,
	Button,
	IconButton,
	LinearProgress,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tooltip,
	Typography,
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { useEffect, useState } from 'react';
import { restServer } from '../../services/AxiosConfiguration';
import {
	ScheduleSlot,
	StaffSchedule,
	StaffWithProfession,
} from '../rest-models/rest-model';
import { addDays, format } from 'date-fns';
import { ISODATEFMT, getMonday } from '../common/DateUtility';
import { Add, Message } from '@mui/icons-material';
import { StaffScheduleDialog } from './StaffSchedule/StaffScheduleDialog';

const fetchStaffData = async (startDate: Date, endDate: Date) => {
	return await restServer
		.get<StaffSchedule[]>(`views/staff-schedule`, {
			params: {
				startDate: format(startDate, 'yyyy-MM-dd'),
				endDate: format(endDate, 'yyyy-MM-dd'),
			},
		})
		.then(res => res.data);
};

const putStaffData = async (
	startDate: Date,
	endDate: Date,
	payload: StaffSchedule[]
) => {
	return await restServer
		.put<StaffSchedule[]>('views/staff-schedule', payload, {
			params: {
				startDate: format(startDate, ISODATEFMT),
				endDate: format(endDate, ISODATEFMT),
			},
		})
		.then(res => res.data);
};

export function StaffSchedulePage() {
	const [userSelectedDate, setUserSelectedDate] = useState<{
		date: Date;
		startDate: Date;
		endDate: Date;
	}>({
		date: new Date(),
		startDate: getMonday(new Date()),
		endDate: addDays(getMonday(new Date()), 5),
	});
	const [staffSchedule, setStaffSchedule] = useState<{
		loading: boolean;
		data: StaffSchedule[];
	}>({
		loading: true,
		data: [],
	});

	const [hasUpdate, setHasUpdate] = useState<boolean>(true);

	//
	useEffect(() => {
		// re-fetch data
		setStaffSchedule(curState => ({ ...curState, loading: true }));
		fetchStaffData(userSelectedDate.startDate, userSelectedDate.endDate).then(
			resp => {
				setStaffSchedule({
					loading: false,
					data: resp,
				});
			}
		);
	}, [userSelectedDate]);

	const uploadData = (
		startDate: Date,
		endDate: Date,
		payload: StaffSchedule[]
	) => {
		setStaffSchedule({ ...staffSchedule, loading: true });
		putStaffData(startDate, endDate, payload).then(resp =>
			setStaffSchedule({ loading: false, data: resp })
		);
	};

	return (
		<Box>
			<Typography variant='h3'>Personalschema</Typography>
			{/* Date picker and save button */}
			<Paper
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
					gap: 2,
					mb: 1,
					// width: 'fit-content',
					// p: '4px',
					py: '4px',
					paddingLeft: 1,
					paddingRight: 2,
				}}
			>
				<Box sx={{ display: 'inherit', alignItems: 'inherit' }}>
					<DesktopDatePicker
						label='Datum'
						value={userSelectedDate.date}
						onChange={newDate => {
							if (newDate !== null) {
								const monday = getMonday(newDate);
								setUserSelectedDate({
									date: newDate,
									startDate: monday,
									endDate: addDays(monday, 7),
								});
							}
						}}
					/>
					<Typography variant='body1' sx={{ ml: 1 }}>
						{`Vecka ${format(userSelectedDate.date, 'I')}`}
					</Typography>
				</Box>
				<Button
					variant='contained'
					color='success'
					disabled={hasUpdate}
					onClick={() => {
						uploadData(
							userSelectedDate.startDate,
							userSelectedDate.endDate,
							staffSchedule.data
						);
						setHasUpdate(true);
					}}
				>
					Spara
				</Button>
			</Paper>

			{/* Main content */}
			{staffSchedule.loading === true ? (
				<LinearProgress />
			) : staffSchedule.data.length === 0 ? (
				'Data saknas'
			) : (
				<StaffTable
					staffSchedule={staffSchedule.data}
					setStaffSchedule={(staffSchedule: StaffSchedule[]) => {
						setStaffSchedule({ loading: false, data: staffSchedule });
						setHasUpdate(false);
					}}
					startDate={userSelectedDate.startDate}
				/>
			)}
		</Box>
	);
}

interface StafftableProps {
	staffSchedule: StaffSchedule[];
	setStaffSchedule: (staffSchedule: StaffSchedule[]) => void;
	startDate: Date;
}

/**
 * Draws a table/week view with all staff and their working times/dates
 * @param props
 * @returns
 */
function StaffTable(props: StafftableProps) {
	const headerDates = Array.from({ length: 5 }, (_, index) =>
		addDays(props.startDate, index)
	);

	const [openDialog, setOpenDialog] = useState<boolean>(false);
	const [userSelection, setUserSelection] = useState<{
		staff: StaffWithProfession;
		schedule: ScheduleSlot | undefined;
		scheduleIndex: number;
		currentDate: Date;
	}>({
		staff: props.staffSchedule[0].staff,
		schedule: undefined,
		scheduleIndex: 0,
		currentDate: new Date(),
	});

	const saveAndUpdate = (
		staff: StaffWithProfession,
		scheduleSlot: ScheduleSlot,
		// createNew: boolean
		updateType: 'new' | 'delete' | 'update'
	) => {
		const copy = [...props.staffSchedule];
		// Finding staff
		const staffFind = copy.find(p => p.staff.id === staff.id);

		if (staffFind) {
			if (updateType === 'new') {
				staffFind.schedule.push({ ...scheduleSlot });
			} else if (updateType === 'update') {
				staffFind.schedule[userSelection.scheduleIndex] = { ...scheduleSlot };
			} else if (updateType === 'delete') {
				staffFind.schedule.splice(userSelection.scheduleIndex, 1);
			}
			// updating state
			props.setStaffSchedule(copy);
		}
	};

	return (
		<>
			<StaffScheduleDialog
				open={openDialog}
				closeDialog={() => setOpenDialog(false)}
				staff={userSelection.staff}
				schedule={userSelection.schedule}
				currentDate={userSelection.currentDate}
				saveAndExit={saveAndUpdate}
			/>

			<TableContainer component={Paper} sx={{ maxHeight: '80vh' }}>
				<Table size='small' stickyHeader>
					<TableHead>
						<TableRow>
							<TableCell>
								<Typography fontWeight='bold'>Namn</Typography>
							</TableCell>

							{headerDates.map(d => (
								<TableCell key={format(d, ISODATEFMT)}>
									<Typography fontWeight='bold' align='center'>
										{format(d, 'eeee dd/MM')}
									</Typography>
								</TableCell>
							))}
						</TableRow>
					</TableHead>

					<TableBody>
						{props.staffSchedule.map(stSched => (
							<TableRow key={stSched.staff.id}>
								<TableCell>{`${stSched.staff.firstName} ${stSched.staff.lastName}`}</TableCell>
								{headerDates.map(hDate => {
									const hDatesStr = format(hDate, ISODATEFMT);
									return (
										<TableCell key={hDate.getDay()}>
											<Box
												sx={{
													display: 'flex',
													alignItems: 'center',
												}}
											>
												<Tooltip title='Lägg till...' placement='right'>
													<IconButton
														sx={{
															opacity: 0.1,
															'&:hover': {
																opacity: 1,
																transition: 'ease-in',
															},
														}}
														onClick={() => {
															setOpenDialog(true);
															setUserSelection({
																staff: stSched.staff,
																schedule: undefined,
																scheduleIndex: 0,
																currentDate: hDate,
															});
														}}
													>
														<Add />
													</IconButton>
												</Tooltip>
												<Box>
													{getScheduleForDay(stSched.schedule, hDatesStr).map(
														({ scheduleSlot, index }) => (
															<Typography
																key={
																	scheduleSlot.startTime +
																	scheduleSlot.endTime +
																	index
																}
																variant='body1'
																component='div'
																sx={{
																	'&:hover': {
																		backgroundColor: t =>
																			t.palette.primary.light,
																		color: t => t.palette.primary.contrastText,
																		cursor: 'grab',
																	},
																	display: 'flex',
																	alignItems: 'center',
																	gap: 1,
																	opacity: scheduleSlot.isAvailable ? 1 : 0.6,
																	color: t =>
																		scheduleSlot.isAvailable === true
																			? t.palette.success.main
																			: t.palette.error.main,
																}}
																onClick={() => {
																	setOpenDialog(true);
																	setUserSelection({
																		...userSelection,
																		staff: stSched.staff,
																		schedule: scheduleSlot,
																		scheduleIndex: index,
																		currentDate: hDate,
																	});
																}}
															>
																{writeStartEndTime(
																	scheduleSlot.startTime,
																	scheduleSlot.endTime
																)}
																{scheduleSlot.description && (
																	<Tooltip
																		title={scheduleSlot.description}
																		placement='right'
																	>
																		<Message color='action' fontSize='small' />
																	</Tooltip>
																)}
															</Typography>
														)
													)}
												</Box>
											</Box>
										</TableCell>
									);
								})}
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</>
	);
}

const writeStartEndTime = (startDate: string, endDate: string) =>
	`${format(new Date(startDate), 'HH:mm')} - ${format(
		new Date(endDate),
		'HH:mm'
	)}`;

/**
 * Getting all scheduleSLot on a specific date
 * @param scheduleSlots
 * @param dayStr yyyy=MM-dd format
 * @returns Array with ScheduleSlot and it's original index in the array.
 */
const getScheduleForDay = (scheduleSlots: ScheduleSlot[], dayStr: string) => {
	return scheduleSlots.reduce<
		Array<{ scheduleSlot: ScheduleSlot; index: number }>
	>((acc, cur, index) => {
		if (cur.startTime.split('T')[0] === dayStr)
			acc.push({ scheduleSlot: cur, index: index });
		return acc;
	}, []);
};
