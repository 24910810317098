import { Box, Button, ButtonProps, Divider, Typography } from '@mui/material';
import { useState } from 'react';

type FontWeight = 'normal' | 'bold' | 'lighter' | 'bolder' | number;

type TextProperties = {
	fontSize?: string | number;
	fontWeight?: FontWeight;
	color?: string;
};

type ButtonWithDialogProps = ButtonProps & {
	dialogTitle?: string;
	dialogText?: string;
	confirmText?: string;
	onConfirm?: () => void;
	cancelText?: string;
	onCancel?: () => void;
	titleProperties?: TextProperties;
	textProperties?: TextProperties;
} & (
		| {
				dialogWidth?: string | number;
				dialogHeight?: string | number;
				dialogAspectRatio?: undefined;
		  }
		| {
				dialogWidth?: string | number;
				dialogAspectRatio?: number;
				dialogHeight?: undefined;
		  }
		| {
				dialogHeight?: string | number;
				dialogAspectRatio?: number;
				dialogWidth?: undefined;
		  }
	);

function BackgroundBox({ children }: { children: JSX.Element }) {
	return (
		<Box
			position={'fixed'}
			top={0}
			left={0}
			zIndex={t => t.zIndex.appBar + 1}
			width={'100svw'}
			height={'100svh'}
			sx={{ backgroundColor: '#0007' }}
			display={'flex'}
			justifyContent={'center'}
			alignItems={'center'}
		>
			{children}
		</Box>
	);
}

export function ButtonWithDialog(props: ButtonWithDialogProps) {
	const {
		dialogTitle,
		dialogText,
		confirmText,
		onConfirm,
		cancelText,
		onCancel,
		titleProperties,
		textProperties,
		dialogWidth,
		dialogHeight,
		dialogAspectRatio,
		...buttonProps
	} = props;
	const [showDialog, setShowDialog] = useState(false);

	function DialogBox() {
		return (
			<Box
				sx={{
					backgroundColor: '#fff',
					minWidth: dialogWidth,
					minHeight: dialogHeight,
					aspectRatio: dialogAspectRatio,
					display: 'flex',
					flexDirection: 'column',
					p: 1,
					borderRadius: 1,
				}}
			>
				<Typography
					fontSize={titleProperties?.fontSize || 20}
					fontWeight={titleProperties?.fontWeight || 'bold'}
					color={titleProperties?.color}
				>
					{dialogTitle}
				</Typography>
				<Divider sx={{ mb: 1 }} />
				<Typography
					fontSize={textProperties?.fontSize || 16}
					fontWeight={textProperties?.fontWeight || 'normal'}
					color={textProperties?.color}
				>
					{dialogText}
				</Typography>
				<Box mt={'auto'} alignSelf={'end'} display={'flex'} gap={1}>
					{onConfirm && (
						<Button
							variant={'contained'}
							color={'success'}
							onClick={() => {
								setShowDialog(false);
								onConfirm();
							}}
						>
							{confirmText || 'Confirm'}
						</Button>
					)}
					<Button
						variant={'contained'}
						color={'error'}
						onClick={() => {
							setShowDialog(false);
							onCancel?.();
						}}
					>
						{cancelText || 'Cancel'}
					</Button>
				</Box>
			</Box>
		);
	}

	return (
		<>
			<Button
				{...buttonProps}
				onClick={() => {
					setShowDialog(true);
				}}
			>
				{props.children}
			</Button>
			{showDialog && (
				<BackgroundBox>
					<DialogBox />
				</BackgroundBox>
			)}
		</>
	);
}
