import React, { createContext, useEffect, useMemo, useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
	Link as RouterLink,
	LinkProps as RouterLinkProps,
} from 'react-router-dom';
import { LinkProps } from '@mui/material/Link';
import chroma from 'chroma-js';

export const LinkBehavior = React.forwardRef<
	HTMLAnchorElement,
	Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }
>((props, ref) => {
	const { href, ...other } = props;
	// Map href (MUI) -> to (react-router)
	return <RouterLink ref={ref} to={href} {...other} />;
});

interface ColorVersion {
	dark: string;
	light: string;
	hover?: string;
}

export interface PaletteColors {
	red: ColorVersion;
	yellow: ColorVersion;
	green: ColorVersion;
	blue: ColorVersion;
	purple: ColorVersion;
	white: ColorVersion;
}

interface IThemeContext {
	paletteColors: PaletteColors;
	setPaletteColors: (palette: PaletteColors) => void;
}

export const SOWNDER_COLORS = {
	red: { light: chroma(227, 24, 54).hex(), dark: chroma(159, 63, 63).hex() },
	yellow: {
		light: chroma(241, 135, 0).hex(),
		dark: chroma(203, 138, 54).hex(),
	},
	green: {
		light: chroma(185, 219, 173).hex(),
		dark: chroma(105, 130, 122).hex(),
		hover: chroma(130, 150, 152).hex(),
	},
	blue: {
		light: chroma(172, 218, 215).hex(),
		dark: chroma(109, 119, 141).hex(),
	},
	purple: {
		light: chroma(110, 54, 140).hex(),
		dark: chroma(134, 101, 154).hex(),
	},
	white: {
		light: chroma(255, 255, 255).hex(),
		dark: chroma(255, 255, 255).hex(),
	},
};

export const AppThemeContext = createContext<IThemeContext>(
	{} as IThemeContext
);

export const AppThemeProvider = (props: { children: React.ReactNode }) => {
	const [palette, setPalette] = useState<PaletteColors>(SOWNDER_COLORS);

	const primaryColor = SOWNDER_COLORS.blue.dark;
	const accentColor = SOWNDER_COLORS.green.dark;

	useEffect(() => {
		const metaThemeColor = document.querySelector('meta[name=theme-color]');
		if (metaThemeColor !== null)
			metaThemeColor.setAttribute('content', primaryColor);
		// eslint-disable-next-line
	}, []);

	const theme = useMemo(
		() =>
			createTheme({
				palette: {
					primary: {
						main: primaryColor,
					},
					secondary: {
						main: accentColor,
					},
				},
				typography: {
					fontFamily: [
						'Helvetica',
						'-apple-system',
						'BlinkMacSystemFont',
						'"Segoe UI"',
						'Roboto',
						'"Helvetica Neue"',
						'Arial',
						'sans-serif',
						'"Apple Color Emoji"',
						'"Segoe UI Emoji"',
						'"Segoe UI Symbol"',
					].join(','),
				},
				components: {
					MuiLink: {
						defaultProps: {
							component: LinkBehavior,
						} as LinkProps,
					},
					MuiButtonBase: {
						defaultProps: {
							disableRipple: true,
							LinkComponent: LinkBehavior,
						},
					},
					MuiButton: { defaultProps: { size: 'small' } },
					MuiTextField: { defaultProps: { size: 'small' } },
					MuiSelect: { defaultProps: { size: 'small' } },
					MuiChip: { defaultProps: { size: 'small' } },
					MuiTable: { defaultProps: { size: 'small' } },
					MuiIconButton: { defaultProps: { size: 'small' } },
					MuiToolbar: {
						defaultProps: { variant: 'dense', disableGutters: true },
					},
					MuiMenuItem: { defaultProps: { dense: true } },
					MuiAutocomplete: { defaultProps: { size: 'small' } },
				},
			}),
		// eslint-disable-next-line
		[palette]
	);

	return (
		<AppThemeContext.Provider
			value={{
				paletteColors: palette,
				setPaletteColors: (palette: PaletteColors) => setPalette(palette),
			}}
		>
			<ThemeProvider theme={theme}>{props.children}</ThemeProvider>
		</AppThemeContext.Provider>
	);
};
