import { ReactNode, useEffect, useState } from 'react';
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	Stack,
	Tab,
	Tabs,
	TextField,
	Typography,
} from '@mui/material';
import Keycloak from 'keycloak-js';
import { Logout } from '@mui/icons-material';

interface UserDialogProps {
	open: boolean;
	onClose: () => void;
	username: string;
	keycloak: Keycloak;
}

export default function UserDialog(props: UserDialogProps) {
	const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);

	return (
		<Dialog maxWidth='lg' fullWidth open={props.open}>
			<DialogContent
				sx={{ p: 0, height: '50vh', borderBottom: 1, borderColor: 'divider' }}
			>
				<Tabs
					value={selectedTabIndex}
					onChange={(_, newValue) => setSelectedTabIndex(newValue)}
				>
					<Tab label='Användare' />
					<Tab label='Annat' />
				</Tabs>

				<TabPanel value={selectedTabIndex} index={0}>
					<UserTab keycloak={props.keycloak} />
				</TabPanel>

				<TabPanel value={selectedTabIndex} index={1}>
					Annat
				</TabPanel>

				{/* <Divider /> */}
			</DialogContent>
			<DialogActions>
				<Typography sx={{ mr: 'auto', ml: 2 }} color='GrayText'>
					{process.env.REACT_APP_VERSION}
				</Typography>
				<Button
					variant='contained'
					onClick={() => {
						props.onClose();
					}}
				>
					Avbryt
				</Button>
				<Button
					variant='contained'
					onClick={() => {
						props.onClose();
					}}
				>
					Ok
				</Button>
			</DialogActions>
		</Dialog>
	);
}

/**
 * Generic component wrapper component for Tab-pages
 * @param props
 * @returns
 */
function TabPanel(props: {
	children?: ReactNode;
	index: number;
	value: number;
}) {
	return (
		<Box hidden={props.value !== props.index}>
			{props.value === props.index && <Box sx={{ p: 3 }}>{props.children}</Box>}
		</Box>
	);
}

/**
 * UserTab showing keycloak login profile
 * fullename and email
 * @param props
 * @returns
 */
function UserTab(props: { keycloak: Keycloak }) {
	const [userInfo, setUserInfo] = useState<{
		fullName: string;
		email: string;
	}>({ fullName: '', email: '' });

	useEffect(() => {
		props.keycloak.loadUserProfile().then(profile => {
			setUserInfo({
				fullName: `${profile.firstName} ${profile.lastName}`,
				email: profile.email ? profile.email : '',
			});
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<Stack component='form' spacing={2} sx={{ width: 500 }}>
				<TextField disabled label='Namn' value={userInfo.fullName} />

				<TextField disabled label='E-post' value={userInfo.email} />
			</Stack>

			<Button
				variant='text'
				startIcon={<Logout />}
				sx={{ mt: 2 }}
				onClick={() => props.keycloak.logout()}
			>
				Logga ut
			</Button>
		</>
	);
}
