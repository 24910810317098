import { Box, Paper, Tab, Tabs, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import {
	Method,
	RESTBaseEntity,
	StaffWithProfession,
} from '../../rest-models/rest-model';
import { GenericComponent } from './GenericComponent';
import { MethodForm } from './forms/MethodForm';
import { EquipmentForm } from './forms/EquipmentForm';
import { ProfessionForm } from './forms/ProfessionForm';
import { CertificateForm } from './forms/CertificateForm';
import { StaffForm, transformStaff } from './forms/StaffForm';
import {
	methodModel,
	equipmentModel,
	certificateModel,
	professionModel,
	staffModel,
	functionModel,
	FunctionTemplate,
} from '../../rest-models/rest-functions';
import { FunctionForm } from './forms/FunctionForm';
import { BaseContext } from '../../../context/BaseProvider';

export interface StaffWithProfessionT extends RESTBaseEntity {
	staff: StaffWithProfession;
}

type SelectionChoices =
	| 'method'
	| 'equipment'
	| 'certificate'
	| 'profession'
	| 'staff'
	| 'function';

export function ManagementPage() {
	const [userSelection, setUserSelection] =
		useState<SelectionChoices>('method');

	const { updateProfessionList } = useContext(BaseContext);

	return (
		<Box>
			<Typography variant='h3'>Hantering</Typography>
			<Paper>
				<Tabs
					sx={{ borderBottom: 1 }}
					value={userSelection}
					onChange={(_event, value) => setUserSelection(value)}
				>
					<Tab value='method' label='Metod' />
					<Tab value='equipment' label='Utrustning' />
					<Tab value='certificate' label='körkort' />
					<Tab value='profession' label='Yrkeskategori' />
					<Tab value='staff' label='Personal' />
					<Tab value='function' label='Funktionsmall' />
				</Tabs>

				<Box
					sx={{
						'.grid-split': {
							p: 2,
							display: 'grid',
							gridTemplateColumns: 'minmax(200px, 300px) auto',
						},
						'.management-form-parent': {
							borderLeft: 1,
							borderColor: 'divider',
							px: 2,
						},
					}}
				>
					<div hidden={userSelection !== 'method'}>
						<GenericComponent<Method>
							dataModel={methodModel}
							title='Metoder'
							newText='Ny metod'
							formComponent={MethodForm}
							defaultObject={{
								id: 0,
								name: '',
								code: 0,
								description: '',
								manager: undefined,
							}}
						/>
					</div>
					<div hidden={userSelection !== 'equipment'}>
						<GenericComponent
							dataModel={equipmentModel}
							title={'Utrustning'}
							newText='Ny utrustning'
							formComponent={EquipmentForm}
							defaultObject={{
								id: 0,
								name: '',
								description: '',
							}}
						/>
					</div>
					<div hidden={userSelection !== 'certificate'}>
						<GenericComponent
							dataModel={certificateModel}
							title={'Utrustning'}
							newText='Ny utrustning'
							formComponent={CertificateForm}
							defaultObject={{
								id: 0,
								name: '',
								description: '',
								active: true,
								method: undefined,
								equipment: [],
								isTraining: false,
								startDate: new Date(),
								endDate: new Date(),
							}}
						/>
					</div>
					<div hidden={userSelection !== 'profession'}>
						<GenericComponent
							postSaveFetch={updateProfessionList}
							dataModel={professionModel}
							title='Yrkeskategori'
							newText='Ny yrkeskategori'
							formComponent={ProfessionForm}
							defaultObject={{
								id: 0,
								abbrev: 'AKRONYM',
								name: '',
								description: '',
							}}
						/>
					</div>
					<div hidden={userSelection !== 'staff'}>
						<GenericComponent<StaffWithProfessionT>
							dataModel={{
								getFunction: () =>
									staffModel
										.getFunction()
										.then(r => r.map(item => transformStaff(item))),
								postFunction: (staffT: StaffWithProfessionT) =>
									staffModel
										.postFunction(staffT.staff)
										.then(r => transformStaff(r)),
								deleteFunction: (pk: number) =>
									staffModel.deleteFunction(pk).then(r => transformStaff(r)),
								putFunction: (staffT: StaffWithProfessionT) =>
									staffModel
										.putFunction(staffT.staff)
										.then(r => transformStaff(r)),
							}}
							title='Personal'
							newText='Ny personal'
							formComponent={StaffForm}
							defaultObject={{
								id: 0,
								name: '',
								description: '',
								staff: {
									id: 0,
									firstName: '',
									lastName: '',
									abbrev: '',
									active: true,
									profession: undefined,
									certificate: [],
								},
							}}
							hideButtons={true}
						/>
					</div>
					<div hidden={userSelection !== 'function'}>
						<GenericComponent<FunctionTemplate>
							dataModel={functionModel}
							title='funktionsmall'
							newText='Ny funktionsmall'
							formComponent={FunctionForm}
							defaultObject={{
								id: 0,
								name: '',
								description: '',
								row: [],
							}}
						/>
					</div>
				</Box>
			</Paper>
		</Box>
	);
}
