import axios from 'axios';
import axiosRetry from 'axios-retry';

export const restServer = axios.create({
	baseURL: (window as any).env.KLINFYS_REST_URL,
	// baseURL: (window as any).env
	// 	? (window as any).env.KLINFYS_REST_URL
	// 	: 'http://127.0.0.1:8090',
});

axiosRetry(restServer, {
	retryDelay: retryCount => 1000 * retryCount,
	retries: 2,
	retryCondition: () => {
		return true;
	},
});

/**
 * Setting up interceptor
 * with Authorization token header
 * @param keycloak
 */
export function AxiosSetup(keycloak: Keycloak.KeycloakInstance) {
	restServer.interceptors.request.use(async function (config) {
		config.timeout = 60000;
		await keycloak.updateToken(30);

		if (config.headers !== undefined)
			config.headers['Authorization'] = `Bearer ${keycloak.token}`;

		return config;
	});
}
