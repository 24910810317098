import React, { useContext, useEffect, useState } from 'react';
import { Equipment, Profession } from '../components/rest-models/rest-model';
import { restServer } from '../services/AxiosConfiguration';

type BaseContextType = {
	// speciality: Speciality[];
	equipmentList: Equipment[];
	professionList: Profession[];
	updateProfessionList: () => void;
};

export const BaseContext = React.createContext<BaseContextType>(
	{} as BaseContextType
);

export const BaseProvider = (props: { children: React.ReactNode }) => {
	// const [speciality, setSpeciality] = useState<Speciality[]>([]);
	const [equipmentList, setEquipment] = useState<Equipment[]>([]);
	const [professionList, setProfession] = useState<Profession[]>([]);

	const updateProfessionList = () => {
		restServer<Profession[]>('/profession').then(item => {
			setProfession(item.data);
		});
	};

	useEffect(() => {
		Promise.all([
			// restServer.get<Speciality[]>('/data/speciality'),
			restServer.get<Equipment[]>('/equipment'),
			restServer.get<Profession[]>('/profession'),
		]).then(([r2, r3]) => {
			// setSpeciality(r1.data);
			setEquipment(r2.data);
			setProfession(r3.data);
		});
	}, []);

	return (
		<BaseContext.Provider
			value={{
				// speciality,
				equipmentList,
				professionList,
				updateProfessionList,
			}}
		>
			{props.children}
		</BaseContext.Provider>
	);
};

export const useBaseData = () => useContext(BaseContext);
