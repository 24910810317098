import { previousMonday } from 'date-fns';

export const ISODATEFMT = 'yyyy-MM-dd';
export const ISODATETIMEFMT = `${ISODATEFMT}'T'HH:mm:ss`;

/**
 * Create a date that matched the date parameter but has the time set using a time string of the form "HH(:MM)(:SS)".
 * Any non-set part of the time string is assumed to be zero. e.g. 7:30 is the same as 7:30:00
 * @param date the date to change the time of
 * @param time the time string "HH(:MM)(:SS)" to set the date to
 */
export const setTime = (date: Date, time: string): Date => {
	let outDate = new Date(date);
	const splitTime = time.split(':');

	if (splitTime.length === 0) {
		throw new Error('Time string does not match "HH(:MM)(:SS)"');
	}

	// Hours
	if (splitTime.length > 0) {
		outDate.setHours(parseInt(splitTime[0]));
	}

	// Minutes
	if (splitTime.length > 1) {
		outDate.setMinutes(parseInt(splitTime[1]));
	} else {
		outDate.setMinutes(0);
	}

	// Seconds
	if (splitTime.length > 2) {
		outDate.setSeconds(parseInt(splitTime[2]));
	} else {
		outDate.setSeconds(0);
	}

	return outDate;
};

/**
 * gets this weeks monday
 * @param date
 * @returns
 */
export const getMonday = (date: Date) =>
	date.getDay() === 1 ? date : previousMonday(date);
