import { Add, DeleteForever, Replay, Save } from '@mui/icons-material';
import {
	SxProps,
	List,
	ListItemButton,
	ListItemText,
	Box,
	TextField,
	IconButton,
	InputAdornment,
	Tooltip,
	Button,
	CircularProgress,
} from '@mui/material';
import { FormMode } from './GenericComponent';
import { ButtonWithDialog } from '../../common/ButtonWithDialog';
export interface GenericFormProp<T> {
	selected: T;
	setSelected: (selected: T | undefined) => void;
	formState: T;
	setFormState: (state: T | undefined) => void;
	formChanged: boolean;
	setFormChanged: (changed: boolean) => void;
	formMode: FormMode;
	setFormMode: (mode: FormMode) => void;
	shouldResetForm: boolean;
	setShouldResetForm: (shouldReset: boolean) => void;
	setRefetch: (refetch: boolean) => void;
}

export function TList<
	T extends { id: number; name: string; description: string | undefined }
>(props: {
	items: T[];
	selected: T | undefined;
	setSelected: (item: T) => void;
}) {
	const shorten = (text: string, maxLength: number = 32) => {
		return `${text.slice(0, maxLength)} ${
			text.length >= maxLength ? ' ...' : ''
		}`;
	};

	return (
		<List
			sx={{
				height: '75vh',
				border: 1,
				overflowY: 'scroll',
				borderColor: 'divider',
				paddingTop: 0,
				'.MuiListItemButton-root.Mui-selected, .MuiListItemButton-root.Mui-selected:hover':
					{
						backgroundColor: t => t.palette.primary.main,
						'.MuiTypography-root': {
							color: t => t.palette.primary.contrastText,
						},
					},
			}}
		>
			{props.items.map((item, index) => (
				<ListItemButton
					key={item.id}
					sx={{ py: 0 }}
					selected={item.id === props.selected?.id}
					onClick={_event => props.setSelected(item)}
					// disableGutters
				>
					<ListItemText
						primary={item.name}
						secondary={item.description && shorten(item.description)}
						secondaryTypographyProps={{ sx: { fontStyle: 'italic' } }}
					/>
				</ListItemButton>
			))}
		</List>
	);
}

export function NameTextField(props: {
	label?: string;
	name: string;
	setName: (newName: string) => void;
	disabled: boolean;
	reset: () => void;
	sx?: SxProps;
}) {
	return (
		<TextField
			sx={{ ...props.sx, '.MuiFormHelperText-root': { textAlign: 'right' } }}
			variant='standard'
			label={props.label ?? 'Namn'}
			value={props.name}
			onChange={event => props.setName(event.target.value)}
			InputProps={{
				endAdornment: (
					<InputAdornment position='end'>
						<Tooltip title='återställ'>
							<span>
								<IconButton
									color='primary'
									disabled={props.disabled}
									onClick={() => props.reset()}
								>
									<Replay />
								</IconButton>
							</span>
						</Tooltip>
					</InputAdornment>
				),
			}}
		/>
	);
}

export function FormButtonGroup(props: {
	variant: FormMode;
	hasChanged: boolean;
	reset: () => void;
	save: () => void;
	add: () => void;
	delete: () => void;
	loading?: boolean;
}) {
	const hasDelete = props.variant === 'edit';
	const hasReset = props.variant === 'edit';
	const isNew = props.variant === 'new';

	return (
		<Box
			sx={{
				mt: 4,
				display: 'flex',
				justifyContent: 'left',
				alignItems: 'center',
				gap: 1,
			}}
		>
			{hasDelete && (
				<ButtonWithDialog
					startIcon={<DeleteForever />}
					variant='contained'
					color='error'
					dialogWidth={500}
					dialogHeight={130}
					onConfirm={props.delete}
					confirmText='Ja'
					cancelText='Nej'
					dialogTitle={'Ta bort?'}
					dialogText={'Är du säker på att du vill ta bort?'}
				>
					Ta bort
				</ButtonWithDialog>
			)}

			{hasReset && (
				<Button
					variant='contained'
					startIcon={<Replay />}
					disabled={!props.hasChanged}
					onClick={props.reset}
				>
					Återställ
				</Button>
			)}

			{isNew ? (
				<Button
					variant='contained'
					startIcon={<Add />}
					disabled={!props.hasChanged}
					onClick={props.add}
				>
					Lägg till
				</Button>
			) : (
				<Button
					variant='contained'
					startIcon={<Save />}
					disabled={!props.hasChanged}
					onClick={props.save}
				>
					Spara
				</Button>
			)}
			{props.loading && <CircularProgress size={20} />}
		</Box>
	);
}
